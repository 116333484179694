.public_header_container {
  background-color: var(--blue);
  min-height: 130px;
}

.public_header_heading {
  color: var(--lt_grey);
}

.public_header_logo {
  display: flex;
  justify-content: center;
}

.public_header_logo img {
  width: 167px;
  height: 99px;
}

.public_header_tagline {
  color: var(--orange);
  font-family: var(--fontC);
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}

.public_header_mobile_menu_expanded {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: auto;
  background-color: var(--lt_grey);
  border-radius: 5% 0 0 5%;
}

.public_header_mobile_menu_exit_icon {
  color: var (--blue);
  font-size: 30px;
  font-weight: bolder;
  text-align: right;
  margin: 1rem;
}

.public_header_mobile_menu_icon {
  position: absolute;
  top: 5px;
  right: 10px;
}

.public_header_mobile_menu_icon_line {
  width: 35px;
  height: 3px;
  background-color: var(--dk_grey);
  margin: 6px 0;
}

.public_header_mobile_menu ul {
  list-style: none;
}

.public_header_mobile_menu_items {
  margin-top: 30px;
  margin-right: 20px;
  text-align: right;
}

.public_header_mobile_menu_items a {
  text-decoration: none;
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: bold;
  font-size: 25px;
}

.public_header_mobile_menu ul :last-child {
  margin-bottom: 30px;
}

.public_header_desktop_menu {
  display: none;
}

.CurrentPage {
  color: var(--orange) !important;
}

@media only screen and (min-width: 768px) {
  .public_header_desktop_menu {
    display: block;
  }

  .public_header_mobile_menu_icon {
    display: none;
  }
  .public_header_logo {
    display: flex;
    justify-content: left;
  }

  .public_header_logo img {
    margin-top: 1rem;
    margin-left: 5%;
    width: 190px;
    height: auto;
  }

  .public_header_desktop_menu ul {
    list-style: none;
    display: flex;
    flex-direction: row;
  }
  .public_header_desktop_flexbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_header_desktop_menu_items {
    margin-top: 30px;
    margin-right: 20px;
    text-align: right;
  }

  .public_header_desktop_menu_items a {
    text-decoration: none;
    color: var(--lt_grey);
    font-family: var(--fontC);
    font-weight: bold;
    font-size: 1.2rem;
  }

  .public_header_desktop_menu_items a:hover {
    color: var(--orange);
    cursor: pointer;
  }

  .public_header_tagline {
    font-size: 1.2rem;
    text-align: left;
    padding-left: 20px;
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .public_header_logo img {
    width: 225px;
    margin-left: 25%;
  }

  .public_header_tagline {
    font-size: 1.5rem;
    margin-left: 4%;
  }

  .public_header_desktop_menu_items {
    margin-right: 30px;
  }

  .public_header_desktop_menu_items a {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 1400px) {
  .public_header_logo img {
    width: 220px;
  }

  .public_header_tagline {
    font-size: 1.2rem;
  }

  .public_header_desktop_menu_items a {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1900px) {
  .public_header_logo img {
    width: 220px;
    margin-left: 35%;
  }

  .public_header_desktop_menu_items a {
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 2500px) {
  .public_header_logo img {
    width: 325px;
    margin-left: 38%;
  }

  .public_header_tagline {
    font-size: 2rem;
    margin-left: 4%;
  }

  .public_header_desktop_menu_items {
    font-size: 2.5rem;
    margin-right: 45px;
  }

  .public_header_desktop_menu_items a {
    font-size: 3rem;
  }
}
