.public_solutions_image1,
.public_solutions_image2,
.public_solutions_image3 {
  display: none;
}

.public_solutions_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public_solutions_flexbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public_solutions_header {
  padding: 20px 0;
  max-width: 90%;
  text-align: center;
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 25px;
  color: var(--orange);
}

.public_solutions_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 20px 0;
}

.public_solutions_item_heading {
  color: var(--blue);
  font-weight: 700;
  font-size: 1.1rem;
  padding: 20px 0;
}

.public_solutions_item_body {
  font-size: 0.8rem;
  color: var(--dk_grey);
}

.public_solutions_item_disclaimer {
  font-size: 0.6rem;
  font-weight: 700;
  padding: 10px 0;
}
.public_solutions_cta {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public_solutions_cta_headline {
  color: var(--orange);
  font-weight: 700;
  font-size: 1.5rem;
}

.public_solutions_cta_tagline {
  color: var(--blue);
  font-size: 1.2rem;
  font-weight: 700;
}

.public_solutions_stay_connected_button {
  background-color: var(--orange);
  color: white;
  font-weight: 700;
  padding: 15px;
  border: none;
  border-radius: 5px;
  margin: 10px 0;
}

.public_solutions_stay_connected_button a {
  text-decoration: none;
  font-family: var(--fontC);
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .public_solutions_image1,
  .public_solutions_image2,
  .public_solutions_image3 {
    display: block;
  }
  .public_solutions_image1 img {
    height: 200px;
    margin-top: 50%;
  }
  .public_solutions_image2 img {
    width: 150px;
    margin-bottom: 55%;
  }

  .public_solutions_image3 img {
    width: 250px;
    margin-top: 55%;
  }

  .public_solutions_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .public_solutions_flexbox {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 95%;
  }

  .public_solutions_header {
    padding: 20px 5%;
    max-width: 90%;
    text-align: center;
    font-family: var(--fontC);
    font-weight: 700;
    font-size: 25px;
    color: var(--orange);
  }

  .public_solutions_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding: 20px 5%;
  }

  .public_solutions_item_heading {
    color: var(--blue);
    font-weight: 700;
    font-size: 1.1rem;
    padding: 20px 0;
  }

  .public_solutions_item_body {
    font-size: 0.8rem;
    color: var(--dk_grey);
  }

  .public_solutions_item_disclaimer {
    font-size: 0.6rem;
    font-weight: 700;
    padding: 10px 0;
  }
  .public_solutions_cta {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .public_solutions_cta_headline {
    color: var(--orange);
    font-weight: 700;
    font-size: 1.5rem;
  }

  .public_solutions_cta_tagline {
    color: var(--blue);
    font-size: 1.2rem;
    font-weight: 700;
  }

  .public_solutions_stay_connected_button {
    background-color: var(--orange);
    color: white;
    font-weight: 700;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin: 10px 0;
  }
}
@media only screen and (min-width: 992px) {
  .public_solutions_image1,
  .public_solutions_image2,
  .public_solutions_image3 {
    display: block;
  }
  .public_solutions_image1 img {
    height: 200px;
    margin-top: 50%;
  }
  .public_solutions_image2 img {
    width: 250px;
    margin-bottom: 55%;
  }

  .public_solutions_image3 img {
    width: 350px;
    margin-top: 35%;
  }

  .public_solutions_header {
    padding: 25px 5%;
    font-size: 35px;
  }

  .public_solutions_item_heading {
    font-size: 1.5rem;
  }

  .public_solutions_item_body {
    font-size: 1rem;
    color: var(--dk_grey);
  }

  .public_solutions_item_disclaimer {
    font-size: 0.8rem;
  }
  .public_solutions_cta {
    padding: 60px 0;
  }

  .public_solutions_cta_headline {
    font-size: 2rem;
  }

  .public_solutions_cta_tagline {
    font-size: 1.5rem;
  }

  .public_solutions_stay_connected_button {
    background-color: var(--orange);
    color: white;
    font-weight: 700;
    font-size: 18px;
    padding: 10px;
  }
}
@media only screen and (min-width: 1200px) {
  .public_solutions_image1,
  .public_solutions_image2,
  .public_solutions_image3 {
    display: block;
  }
  .public_solutions_image1 img {
    height: 200px;
    margin-top: 50%;
  }
  .public_solutions_image2 img {
    width: 250px;
    margin-bottom: 55%;
  }

  .public_solutions_image3 img {
    width: 350px;
    margin-top: 25%;
  }

  .public_solutions_header {
    padding: 25px 5%;
    font-size: 35px;
  }

  .public_solutions_item_heading {
    font-size: 1.5rem;
  }

  .public_solutions_item_body {
    font-size: 1rem;
    color: var(--dk_grey);
  }

  .public_solutions_item_disclaimer {
    font-size: 0.8rem;
  }
  .public_solutions_cta {
    padding: 60px 0;
  }

  .public_solutions_cta_headline {
    font-size: 2rem;
  }

  .public_solutions_cta_tagline {
    font-size: 1.5rem;
  }

  .public_solutions_stay_connected_button {
    background-color: var(--orange);
    color: white;
    font-weight: 700;
    font-size: 18px;
    padding: 10px;
  }
}
@media only screen and (min-width: 1400px) {
  .public_solutions_image1,
  .public_solutions_image2,
  .public_solutions_image3 {
    display: block;
  }
  .public_solutions_image1 img {
    height: 270px;
    margin-top: 50%;
  }
  .public_solutions_image2 img {
    width: 320px;
    margin-bottom: 25%;
  }

  .public_solutions_image3 img {
    width: 375px;
    margin-top: 20%;
  }

  .public_solutions_header {
    padding: 25px 5%;
    font-size: 35px;
  }

  .public_solutions_item_heading {
    font-size: 2rem;
    padding: 20px 40px;
  }

  .public_solutions_item_body {
    font-size: 1.5rem;
    color: var(--dk_grey);
    padding: 0 35px;
  }

  .public_solutions_item_disclaimer {
    font-size: 1.1rem;
    padding: 0 35px;
  }
  .public_solutions_cta {
    padding: 60px 0;
  }

  .public_solutions_cta_headline {
    font-size: 2.5rem;
  }

  .public_solutions_cta_tagline {
    font-size: 1.5rem;
  }

  .public_solutions_stay_connected_button {
    background-color: var(--orange);
    color: white;
    font-weight: 700;
    font-size: 18px;
    padding: 10px;
  }
}
@media only screen and (min-width: 1500px) {
  .public_solutions_container {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 2500px) {
  .public_solutions_image1,
  .public_solutions_image2,
  .public_solutions_image3 {
    display: block;
  }
  .public_solutions_image1 img {
    height: 370px;
    margin-top: 50%;
  }
  .public_solutions_image2 img {
    width: 450px;
    margin-bottom: 25%;
  }

  .public_solutions_image3 img {
    width: 450px;
    margin-top: 20%;
  }

  .public_solutions_header {
    padding: 25px 5%;
    font-size: 55px;
  }

  .public_solutions_item_heading {
    font-size: 2.5rem;
    padding: 20px 40px;
  }

  .public_solutions_item_body {
    font-size: 2rem;
    color: var(--dk_grey);
    padding: 0 40px;
  }

  .public_solutions_item_disclaimer {
    font-size: 1.5rem;
    padding: 0 35px;
  }
  .public_solutions_cta {
    padding: 60px 0;
  }

  .public_solutions_cta_headline {
    font-size: 3.25rem;
  }

  .public_solutions_cta_tagline {
    font-size: 2.5rem;
  }

  .public_solutions_stay_connected_button {
    background-color: var(--orange);
    color: white;
    font-weight: 700;
    font-size: 25px;
    padding: 15px;
    margin-top: 30px;
  }
}
