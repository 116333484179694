.public_order_site_summary_container {
  padding: 1rem 1rem 5rem 1rem;
}

.public_order_container h1 {
  text-align: center;
  width: 100%;
}

.site_edit_link {
  color: var(--orange) !important;
  text-decoration: underline;
  font-weight: bold;
}

.error_container {
  width: 100%;
  background-color: yellow;
  text-align: center;
  padding: 1rem;
}
.public_site_summary_error_message {
  background-color: yellow;
  color: red;
  font-weight: bold;
  font-size: 1.2em;
}

.public_order_add_site_button {
  margin-top: 1rem;
  font-size: 0.8rem;
  background: green;
  color: var(--lt_grey);
}

.public_order_site_table {
  margin: 2rem 0;
  width: 100%;
  font-size: 0.9rem;
  border-collapse: collapse;
}

.public_order_site_table thead tr {
  background-color: var(--blue);
  border-color: red;
}

.public_order_site_table tbody tr:nth-child(even) {
  background-color: var(--lt_grey);
}
.public_order_site_table tbody tr:nth-child(odd) {
  background-color: white;
}

.public_order_site_table_heading {
  text-align: center;
  padding: 2px;
  margin: 0;
  color: white;
}

.public_order_site_table_heading span {
  font-size: 0.5em;
  font-weight: normal;
  padding-bottom: 0.3em;
}
.public_order_site_table_body td {
  color: black;
  text-align: center;
  /* border: 1px solid black; */
}

.public_order_site_summary_navigation_buttons {
  margin: 4rem 0 1rem 0;
}
.public_order_site_summary_navigation_buttons button {
  margin-top: 1rem;
  padding: 1.2rem;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  font-family: var(--fontC);
}

/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  .public_order_site_summary_container {
    padding: 2rem 4rem 8rem 4rem !important;
  }

  .public_order_site_summary_navigation_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_order_site_summary_navigation_buttons button {
    padding: 1rem;
    font-size: 1rem;
  }
}
/* *****************************  992  ********************************************************* */
@media only screen and (min-width: 992px) {
  .public_order_site_summary_container {
    padding: 2rem 6rem 8rem 6rem !important;
  }

  .error_container {
    padding: 1rem;
  }
  .public_site_summary_error_message {
    font-size: 1.2em;
  }

  .public_order_add_site_button {
    font-size: 1rem;
  }

  .public_order_site_table_heading {
    font-size: 1.2rem;
  }
  .public_order_site_table_body td {
    font-size: 1.2rem;
  }
  .public_order_totals_table {
    font-size: 1.5rem;
    margin: 4rem 0;
  }
}
/* *****************************  1200  ********************************************************* */
@media only screen and (min-width: 1200px) {
  .public_order_site_summary_container {
    padding: 2rem 8rem 10rem 8rem !important;
  }

  .error_container {
    padding: 1rem;
  }
  .public_site_summary_error_message {
    font-size: 1.2em;
  }

  .public_order_add_site_button {
    font-size: 1rem;
  }

  .public_order_site_table_heading {
    font-size: 1.4rem;
  }
  .public_order_site_table_body td {
    font-size: 1.4rem;
  }
  .public_order_totals_table {
    font-size: 1.5rem;
    margin: 4rem 0 2rem 0;
  }

  .public_order_site_summary_navigation_buttons button {
    padding: 1rem;
    font-size: 1.2rem;
  }
}

/* *****************************  1400  ********************************************************* */
@media only screen and (min-width: 1400px) {
  .public_order_site_summary_container {
    padding: 2rem 14rem 10rem 14rem !important;
  }
}
/* *****************************  1900  ********************************************************* */
@media only screen and (min-width: 1900px) {
  .public_order_site_summary_container {
    padding: 2rem 20rem 10rem 20rem !important;
  }

  .error_container {
    padding: 1rem;
  }
  .public_site_summary_error_message {
    font-size: 1.2em;
  }

  .public_order_add_site_button {
    font-size: 1rem;
  }

  .public_order_site_table_heading {
    font-size: 1.4rem;
  }
  .public_order_site_table_body td {
    font-size: 1.4rem;
  }
  .public_order_totals_table {
    font-size: 1.5rem;
    margin: 4rem 0 2rem 0;
  }

  .public_order_site_summary_navigation_buttons button {
    padding: 1.2rem;
    font-size: 1.5rem;
  }
}
/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  .public_order_site_summary_container {
    padding: 2rem 25rem 10rem 25rem !important;
  }

  .error_container {
    padding: 1rem;
  }
  .public_site_summary_error_message {
    font-size: 1.2em;
  }

  .public_order_add_site_button {
    font-size: 1.2rem;
  }

  .public_order_site_table_heading {
    font-size: 1.4rem;
  }
  .public_order_site_table_body td {
    font-size: 1.4rem;
  }
  .public_order_totals_table {
    font-size: 1.5rem;
    margin: 4rem 0 2rem 0;
  }
  .public_order_site_summary_navigation_buttons button {
    padding: 1.2rem;
    font-size: 1.5rem;
  }
}

/* *****************************  2900  ********************************************************* */
@media only screen and (min-width: 2900px) {
  .public_order_site_summary_container {
    padding: 2rem 30rem 10rem 30rem !important;
  }

  .public_order_site_summary_navigation_buttons button {
    padding: 1.5rem;
    font-size: 1.7rem;
  }
}
