.public_viewSiteOrder_heading {
  color: var(--blue);
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.public_viewSiteOrder_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.public_viewSiteOrder_buttons button {
  margin: 1rem 0;
  padding: 0.8rem;
  font-size: 1rem;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

.public_viewSiteOrder_buttons button:first-child {
  background-color: green;
}

.public_viewSiteOrder_buttons button:nth-child(2) {
  background-color: rgb(255, 230, 1);
  color: black;
}

.public_viewSiteOrder_buttons button:last-child {
  background-color: red;
}

.public_viewSiteOrder_section_heading {
  color: var(--orange);
  font-weight: bold;
  font-size: 1.3rem;
}

.public_viewSiteOrder_plan_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.public_viewSiteOrder_table {
  margin: 1rem 0;
  border-collapse: collapse;
}

.public_viewSiteOrder_table th,
.public_viewSiteOrder_table td {
  font-family: var(--fontC);
  font-size: 0.85rem;
  text-align: center;
  padding: 5px;
}

.public_viewSiteOrder_table th {
  background-color: var(--blue);
  color: white;
}
.public_viewSiteOrder_table td {
  background-color: var(--lt_grey);
}

.public_viewSite_site_cost_container {
  padding: 0.5rem;
  margin: 1rem 0;
  border-radius: 5px;
  font-size: 1.2rem;
  font-family: var(--fontC);
}

.public_viewSite_site_cost_container th {
  text-align: left;
  color: var(--blue);
  font-weight: bold;
}
.public_viewSite_site_details_container {
  padding: 0.5rem;
  margin: 1rem 0;
  border-radius: 5px;
  font-size: 1.1rem;
  text-align: left;
}

.public_viewSite_site_details_container table {
  font-family: var(--fontC);
  margin-top: 1.2rem;
}
.public_viewSite_site_details_container th {
  color: var(--blue);
  font-weight: bold;
}
.public_viewSite_site_details_container td {
  padding-left: 0.2rem;
}
.public_viewSite_site_notes_container {
  margin-top: 1.2rem;
}

.public_viewSite_site_notes_container table {
  margin-top: 1rem;
  padding: 0.5rem;
  font-size: 1.1rem;
  font-family: var(--fontC);
  border: 2px solid var(--orange);
  width: 100%;
}

/* *****************************  576  ********************************************************* */
@media only screen and (min-width: 576px) {
  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1rem;
  }
}
/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1.1rem;
  }

  .public_viewSite_site_cost_container {
    font-size: 1.2rem;
  }
  .public_viewSite_site_details_container table {
    font-size: 1.3rem;
  }
  .public_viewSite_site_notes_container table {
    font-size: 1.2rem;
    padding-bottom: 3rem;
  }
}
/* *****************************  992  ********************************************************* */
@media only screen and (min-width: 992px) {
  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1.2rem;
  }

  .public_viewSite_site_cost_container {
    font-size: 1.3rem;
  }
  .public_viewSite_site_details_container table {
    font-size: 1.4rem;
  }
  .public_viewSite_site_notes_container table {
    font-size: 1.3rem;
    padding-bottom: 5rem;
  }
}
/* *****************************  1200  ********************************************************* */
@media only screen and (min-width: 1200px) {
  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1.3rem;
  }

  .public_viewSite_site_cost_container {
    font-size: 1.4rem;
  }
  .public_viewSite_site_details_container table {
    font-size: 1.5rem;
  }
  .public_viewSite_site_notes_container table {
    font-size: 1.4rem;
    padding-bottom: 5rem;
  }
}
/* *****************************  1400  ********************************************************* */
@media only screen and (min-width: 1400px) {
  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1.3rem;
  }

  .public_viewSite_site_cost_container {
    font-size: 1.5rem;
  }
  .public_viewSite_site_details_container table {
    font-size: 1.5rem;
  }
  .public_viewSite_site_notes_container table {
    font-size: 1.4rem;
    padding-bottom: 5rem;
  }
}
/* *****************************  1900  ********************************************************* */
@media only screen and (min-width: 1900px) {
  .public_viewSiteOrder_heading {
    font-size: 2.2rem;
    padding: 3rem 0;
  }

  .public_viewSiteOrder_section_heading {
    font-size: 1.6rem;
  }

  .public_viewSiteOrder_buttons button {
    font-size: 1.5rem;
  }

  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1.5rem;
  }

  .public_viewSite_site_cost_container {
    font-size: 1.8rem;
  }
  .public_viewSite_site_details_container table {
    font-size: 1.7rem;
  }
  .public_viewSite_site_notes_container table {
    font-size: 1.6rem;
    padding-bottom: 5rem;
  }
}
/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  .public_viewSiteOrder_heading {
    font-size: 3rem;
    padding: 4rem 0;
  }

  .public_viewSiteOrder_section_heading {
    font-size: 1.8rem;
  }

  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1.8rem;
  }

  .public_viewSite_site_cost_container {
    font-size: 2rem;
  }
  .public_viewSite_site_details_container table {
    font-size: 1.9rem;
  }
  .public_viewSite_site_notes_container table {
    font-size: 1.8rem;
    padding-bottom: 5rem;
  }
}
/* *****************************  2900  ********************************************************* */
@media only screen and (min-width: 2900px) {
}
