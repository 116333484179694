@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;900&family=Source+Sans+Pro:ital,wght@0,400;0,700;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.public_hero_container::before {
  content: "";
  background-image: url(/static/media/ConnectedDots.70f5a600.png);
  background-repeat: repeat;
  position: absolute;
  top: 200px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  opacity: 0.25;
  z-index: -3;
}

.public_hero_mobile_flexbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stayConnected_button_desktop {
  display: none;
}

.public_hero_text_headline {
  margin-top: 20px;
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 25px;
  text-align: center;
}

.public_hero_text_description {
  padding: 5px 25px;
  text-align: center;
  font-family: var(--fontB);
  font-size: 15px;
  color: var(--dk_grey);
}

.public_hero_text_description span {
  color: var(--orange);
  font-weight: 700;
}

.public_hero_image img {
  margin-top: 15px;
  width: 100%;
}

.stayConnected_button {
  background-color: var(--orange);
  border: none;
  padding: 15px 10px;
  border-radius: 10px;
  font-family: var(--fontA);
  font-weight: 700;
  font-size: 15px;
  color: var(--lt_grey);
  margin-top: 30px;
  margin-bottom: 20px;
  cursor: pointer;
}
.stayConnected_button a {
  text-decoration: none;
  font-family: var(--fontA);
  font-weight: 700;
  font-size: 15px;
  color: var(--lt_grey);
  cursor: pointer;
}
.stayConnected_button:hover {
  background-color: var(--orangeHover);
  color: var(--blue);
}

@media only screen and (min-width: 576px) {
  .public_hero_text_description {
    padding: 5px 45px;
    font-size: 18px;
  }
  .public_hero_image img {
    width: 500px;
  }

  .public_hero_text_headline {
    font-size: 35px;
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .public_hero_text {
    width: 100%;
    padding: 0 0 0 5%;
  }

  .public_hero_text_headline {
    font-weight: 700;
    font-size: 35px;
  }

  .public_hero_text_description {
    font-size: 20px;
  }

  .public_hero_image img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) {
  .public_hero_mobile_flexbox {
    flex-direction: row;
    justify-content: space-between;
  }

  .public_hero_button_mobile {
    display: none;
  }
  .stayConnected_button_desktop {
    display: block;
    background-color: var(--orange);
    border: none;
    padding: 15px 10px;
    border-radius: 5px;
    font-family: var(--fontC);
    font-size: 15px;
    margin-top: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .stayConnected_button_desktop a {
    text-decoration: none;
    color: var(--lt_grey);
    cursor: pointer;
  }
  .stayConnected_button_desktop:hover {
    background-color: var(--orangeHover);
    color: var(--blue);
  }
  .public_hero_image img {
    margin-top: 0;
    width: 500px;
    margin-right: 40px;
  }
  .public_hero_text {
    width: 100%;
    padding: 0 0 0 5%;
  }

  .public_hero_text_headline {
    padding: 0;
    margin-top: 20px;
    font-weight: 700;
    font-size: 35px;
    text-align: left;
    max-width: 80%;
  }

  .public_hero_text_description {
    padding: 0 25px 0 0;
    text-align: left;
    font-size: 20px;
    max-width: 80%;
  }
}

@media only screen and (min-width: 1500px) {
  .public_hero_container {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 1900px) {
  .public_hero_image img {
    width: 700px;
    margin-right: 80px;
  }
  .public_hero_text_headline {
    margin-top: 20px;
    font-weight: 700;
    font-size: 40px;
    text-align: left;
    max-width: 80%;
  }

  .public_hero_text_description {
    padding: 0 25px 0 0;
    text-align: left;
    font-size: 23px;
    max-width: 80%;
  }
}
@media only screen and (min-width: 2500px) {
  .public_hero_mobile_flexbox {
    flex-direction: row;
    justify-content: space-between;
  }

  .public_hero_button_mobile {
    display: none;
  }
  .stayConnected_button_desktop {
    padding: 25px 18px;
    border-radius: 15px;
    font-size: 29px;
    color: var(--blue);
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .public_hero_image img {
    width: 1024px;
    margin-right: 100px;
  }
  .public_hero_text {
    width: 100%;
    padding: 0 0 0 5%;
  }

  .public_hero_text_headline {
    padding: 0;
    margin-top: 20px;
    font-weight: 700;
    font-size: 65px;
    text-align: left;
    max-width: 80%;
  }

  .public_hero_text_description {
    padding: 0 25px 0 0;
    text-align: left;
    font-size: 50px;
    max-width: 80%;
  }
}

.public_whatsincluded_container {
  margin: 0;
  padding: 0;
}

.public_whatsincluded_image {
  display: none;
}

.public_whatsincluded_headline {
  color: var(--orange);
  font-family: var(--fontA);
  font-weight: 700;
  text-align: center;
  font-size: 1.5rem;
  padding: 40px 20px;
}

.public_whatsincluded_card {
  /* background-color: var(--lt_grey); */
  padding: 10px 20px;
}

.public_whatsincluded_card_headline {
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 1.3rem;
}

.public_whatsincluded_card_body {
  font-family: var(--fontC);
  font-size: 0.9rem;
  color: var(--dk_grey);
}

.public_whatsincluded_ul_no_decoration {
  list-style: none;
}
.public_whatsincluded_description_nolist {
  margin: 0;
  padding: 0 10px;
}

.public_whatsincluded_button {
  text-align: center;
  padding: 0px 0px 20px 0px;
}

.public_whatsincluded_button button {
  background-color: var(--orange);
  border: none;
  border-radius: 5px;
  padding: 12px;
  color: var(--lt_grey);
  font-size: 1rem;
  font-family: var(--fontC);
}
.public_whatsincluded_button button a {
  text-decoration: none;
  color: var(--lt_grey);
  font-size: 1rem;
  font-family: var(--fontC);
}

.public_whatsincluded_button button:hover {
  background-color: var(--orangeHover);
  color: black;
}
.public_whatsincluded_installation_card {
  background-color: var(--blue);
  border-radius: 5px;
  margin: 20px;
}

.public_whatsincluded_installation_headline {
  color: var(--lt_grey);
  font-weight: 700;
  font-family: var(--fontB);
  padding: 10px 0px;
  font-size: 18px;
  text-align: center;
}

.public_whatsincluded_installation_body {
  color: var(--lt_grey);
  font-family: var(--fontB);
  padding: 0px 10px 20px 10px;
  font-size: 15px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .public_whatsincluded_headline {
    font-size: 2rem;
  }

  .public_whatsincluded_card_headline {
    font-size: 1.5rem;
  }

  .public_whatsincluded_card_body {
    font-size: 1.2rem;
  }

  .public_whatsincluded_installation_headline {
    font-size: 22px;
  }

  .public_whatsincluded_installation_body {
    padding: 0px 20px 30px 20px;
    font-size: 18px;
  }

  .public_whatsincluded_button button {
    padding: 15px;
    font-size: 1.2rem;
  }
  .public_whatsincluded_button button a {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1200px) {
  .public_whatsincluded_container {
    padding: 0 3%;
  }

  .public_whatsincluded_cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_whatsincluded_headline {
    font-size: 2rem;
  }

  .public_whatsincluded_card_headline {
    text-align: center;
    font-size: 1.65rem;
  }

  .public_whatsincluded_card_body {
    font-size: 1.3rem;
  }

  .public_whatsincluded_installation_card {
    padding: 10px;
    width: 600px;
    margin-right: 5%;
  }

  .public_whatsincluded_installation_headline {
    font-size: 40px;
  }

  .public_whatsincluded_installation_body {
    font-size: 25px;
  }

  .public_whatsincluded_button button {
    padding: 15px;
    font-size: 1rem;
  }
  .public_whatsincluded_button button a {
    font-size: 1rem;
  }
  .public_whatsincluded_image {
    display: block;
    margin-bottom: 20px;
    margin-left: 40px;
    max-height: 100px;
  }
  .public_whatsincluded_image img {
    max-height: 250px;
  }
  .public_whatsincluded_flexbox {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width: 1400px) {
  .public_whatsincluded_installation_card {
    margin-right: 15%;
  }
}

@media only screen and (min-width: 1500px) {
  .public_whatsincluded_container {
    padding: 0 5%;
  }
}
@media only screen and (min-width: 1900px) {
  .public_whatsincluded_headline {
    font-size: 3rem;
  }

  .public_whatsincluded_card_headline {
    font-size: 2rem;
  }

  .public_whatsincluded_installation_card {
    padding: 30px;
  }

  .public_whatsincluded_installation_headline {
    font-size: 35px;
  }

  .public_whatsincluded_installation_body {
    padding: 0px 20px 30px 20px;
    font-size: 20px;
  }

  .public_whatsincluded_button button {
    padding: 15px;
    font-size: 1.5rem;
  }
  .public_whatsincluded_button button a {
    font-size: 1.2rem;
  }

  .public_whatsincluded_flexbox {
    margin-top: 20px;
  }

  .public_whatsincluded_installation_card {
    padding: 10px;
    width: 600px;
    margin-right: 32%;
  }
}

@media only screen and (min-width: 2500px) {
  .public_whatsincluded_installation_card {
    margin-right: 50%;
  }

  .public_whatsincluded_headline {
    font-size: 4rem;
  }

  .public_whatsincluded_card_headline {
    font-size: 3.5rem;
    padding-bottom: 40px;
  }

  .public_whatsincluded_card_body {
    font-size: 2rem;
    line-height: 50px;
  }

  .public_whatsincluded_image img {
    max-height: 300px;
  }
}

.customerPortal {
  color: var(--orange);
  font-size: 1rem;
}

.portalFeaturesList {
  /* margin-left: 1rem; */
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media only screen and (min-width: 576px) {
  .customerPortal {
    font-size: 1.5rem;
  }
  .portalFeaturesList {
    margin-left: 6rem;
    margin-top: 2rem;
  }
}

@media only screen and (min-width: 2400px) {
  .customerPortal {
    font-size: 2.5rem;
  }
}

.public_contact_container_flexbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public_contact_success_message {
  background-color: green;
  width: 100%;
  color: white;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  font-size: 23px;
  padding: 10px;
}

.public_contact_form_flexbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  width: 100%;
}

.public_contact_title {
  padding-top: 20px;
  font-family: var(--fontC);
  font-size: 1.7rem;
  font-weight: 700;
  color: var(--blue);
}

.public_contact_form {
  display: flex;
  flex-direction: column;
  align-items: left;
  min-width: 90%;
  padding: 20px 0;
}

.public_contact_form label {
  text-align: left !important;
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  padding: 5px;
}

.public_contact_form input {
  min-width: 95%;
  padding: 8px;
  border-color: var(--lt_grey);
  border-radius: 10px;
  font-family: var(--fontC);
  font-size: 15px;
}

.public_contact_form textarea {
  padding: 10px;
  min-width: 95%;
  border-color: var(--lt_grey);
  border-radius: 10px;
  font-family: var(--fontC);
  font-size: 15px;
}

.public_contact_button_group button {
  padding: 10px;
  border: none;
  color: white;
  border-radius: 10px;
  font-family: var(--fontA);
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.public_contact_button_reset {
  background-color: red;
  margin-right: 10px;
}

.public_contact_button_submit {
  background-color: var(--blue);
  margin-right: 10px;
}

.public_contact_information {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
}
.public_contact_information_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public_contact_information_heading {
  color: var(--blue);
  font-family: var(--fontC);
  font-size: 1.5rem;
  padding: 20px 0 5px 0;
}
.public_contact_information_body {
  color: var(--orange);
  font-family: var(--fontC);
  font-size: 1.25rem;
}

@media only screen and (min-width: 576px) {
  .public_contact_title {
    font-size: 2rem;
  }

  .public_contact_form input {
    font-size: 15px;
  }

  .public_contact_form textarea {
    font-size: 15px;
  }

  .public_contact_button_group button {
    padding: 15px;
    font-size: 18px;
  }

  .public_contact_information_heading {
    font-size: 2rem;
  }
  .public_contact_information_body {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 768px) {
  .public_contact_title {
    font-size: 2rem;
  }

  .public_contact_form input {
    font-size: 15px;
  }

  .public_contact_form textarea {
    font-size: 15px;
  }

  .public_contact_button_group button {
    padding: 15px;
    font-size: 18px;
  }

  .public_contact_information_heading {
    font-size: 2rem;
  }
  .public_contact_information_body {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 992px) {
  .public_contact_title {
    font-size: 2rem;
  }

  .public_contact_form input {
    font-size: 15px;
  }
  .public_contact_form label {
    font-size: 20px;
  }

  .public_contact_form textarea {
    font-size: 15px;
  }

  .public_contact_button_group button {
    padding: 15px;
    font-size: 18px;
  }

  .public_contact_information_heading {
    font-size: 2rem;
  }
  .public_contact_information_body {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .public_contact_container_flexbox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .public_contact_form_flexbox {
    width: 50%;
  }
  .public_contact_title {
    font-size: 2rem;
  }

  .public_contact_form input {
    font-size: 15px;
  }
  .public_contact_form label {
    font-size: 20px;
  }

  .public_contact_form textarea {
    font-size: 15px;
  }

  .public_contact_button_group button {
    padding: 15px;
    font-size: 18px;
  }

  .public_contact_information {
    width: 50%;
  }

  .public_contact_information_heading {
    font-size: 2rem;
  }
  .public_contact_information_body {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1400px) {
  .public_contact_title {
    font-size: 2rem;
  }

  .public_contact_form input {
    font-size: 15px;
  }
  .public_contact_form label {
    font-size: 22px;
    padding: 5px 0px;
  }

  .public_contact_form textarea {
    font-size: 15px;
  }

  .public_contact_button_group button {
    padding: 15px;
    font-size: 18px;
  }

  .public_contact_information {
    width: 50%;
  }

  .public_contact_information_heading {
    font-size: 2.2rem;
  }
  .public_contact_information_body {
    font-size: 1.75rem;
  }
}
@media only screen and (min-width: 1900px) {
  .public_contact_title {
    font-size: 2.5rem;
  }

  .public_contact_form input {
    font-size: 15px;
  }
  .public_contact_form label {
    font-size: 22px;
    padding: 5px 0px;
  }

  .public_contact_form textarea {
    font-size: 15px;
  }

  .public_contact_button_group button {
    padding: 15px;
    font-size: 18px;
  }

  .public_contact_information {
    width: 50%;
  }

  .public_contact_information_heading {
    font-size: 2.2rem;
  }
  .public_contact_information_body {
    font-size: 1.75rem;
  }
}

.public_pricing_container {
  padding-top: 1rem;
}

#bundleSelect {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.public_pricing_bundleSelector_label {
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0 5px 0 10px;
}

.public_pricing_bundleSelector_select {
  color: var(--orange);
  font-family: var(--fontC);
  /* font-weight: 700; */
  font-size: 0.95rem;
  padding: 2px 5px 2px 5px;
  border-color: var(--lt_grey);
  margin-top: 0.5rem;
  width: 40%;
}

.public_bundle_information_container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-left: 1rem;
}

.public_bundle_information_header {
  font-family: var(--fontC);
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--orange);
}

.public_bundle_information_list ul {
  list-style: circle;
}

.public_bundle_information_list_item {
  font-family: var(--fontC);
  color: var(--dk_grey);
  font-size: 0.8rem;
  font-weight: 700;
}

.public_pricing_table_container {
  padding: 0 10px;
}

.public_pricing_table {
  width: 95%;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
}

.public_pricing_table,
.public_pricing_table th,
.public_pricing_table td {
  border: 1px solid var(--blue);
}

.public_pricing_headline {
  color: var(--orange);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 1.5rem;
  padding-left: 10px;
  padding-bottom: 5px;
}

.public_pricing_table_heading {
  color: var(--lt_grey);
  font-size: 0.65rem;
  border: 1px solid var(--blue);
  text-align: center;
}

.public_pricing_table_heading tr {
  background-color: var(--blue);
}

.public_pricing_table_body tr {
  font-family: var(--fontC);
  font-size: 0.65rem;
  text-align: center;
}

.public_pricing_table_body tr:nth-child(even) {
  background-color: white;
}
.public_pricing_table_body tr:nth-child(odd) {
  background-color: var(--lt_grey);
}

.public_pricing_overage_disclaimer {
  font-size: 10px;
  text-align: right;
  margin-right: 10px;
  margin-top: 5px;
}

.public_orderShippingProcess_container {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-left: 1rem;
}

.public_orderShippingProcess_flexbox {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.public_orderShippingProcess_headline {
  font-family: var(--fontC);
  color: var(--orange);
  font-weight: 700;
  font-size: 1.5rem;
}

.public_orderShippingProcess_list {
  max-width: 90%;
}

.public_orderShippingProcess_list_items {
  font-family: var(--fontC);
  color: var(--dk_grey);
  font-size: 0.8rem;
  font-weight: 700;
}

.public_pricing_order_button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--orange);
  width: 150px;
  margin: auto;
  padding: auto;
}

.public_pricing_order_button a {
  text-decoration: none;
  font-family: var(--fontC);
  font-weight: 700;
  color: var(--blue);
}
.pricing_gears_1,
.pricing_art_1,
.public_pricing_order_button_top,
.pricing_art_4 {
  display: none;
}

@media only screen and (min-width: 576px) {
  .public_pricing_bundleSelector_label {
    font-size: 1.5rem;
  }

  .public_pricing_bundleSelector_select {
    font-size: 1.2rem;
  }

  .public_bundle_information_header {
    font-size: 1.75rem;
  }
  .public_bundle_information_list_item {
    font-size: 1.2rem;
  }
  .public_pricing_headline {
    font-size: 1.75rem;
  }
  .public_pricing_table_heading {
    font-size: 0.95rem;
  }
  .public_pricing_table_body tr {
    font-size: 0.95rem;
  }
  .public_pricing_overage_disclaimer {
    margin-right: 15px;
  }

  .public_orderShippingProcess_headline {
    font-size: 1.75rem;
  }
  .public_orderShippingProcess_list_items {
    font-size: 1.2rem;
  }

  .public_pricing_order_button {
    padding: 15px 10px;
    width: 170px;
  }
}
@media only screen and (min-width: 768px) {
  .public_pricing_bundleSelector_label {
    font-size: 1.5rem;
  }

  .public_pricing_bundleSelector_select {
    font-size: 1.2rem;
  }

  .public_bundle_information_header {
    font-size: 1.75rem;
  }
  .public_bundle_information_list_item {
    font-size: 1rem;
  }
  .public_pricing_headline {
    font-size: 1.75rem;
  }
  .public_pricing_table_heading {
    font-size: 1.2rem;
  }
  .public_pricing_table_body tr {
    font-size: 1.2rem;
  }
  .public_pricing_overage_disclaimer {
    margin-right: 15px;
  }

  .public_orderShippingProcess_headline {
    font-size: 1.75rem;
  }
  .public_orderShippingProcess_list_items {
    font-size: 1.2rem;
  }

  .public_pricing_order_button {
    padding: 15px 10px;
    width: 170px;
  }
  .pricing_art_1 {
    display: none;
    width: 300px;
    padding-left: 25px;
  }


  .public_pricing_order_button_top {
    display: block;
    padding: 15px 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--orange);
    width: 150px;
  }

  .public_pricing_order_button_top a {
    text-decoration: none;
    font-family: var(--fontC);
    font-size: 16px;
    color: var(--lt_grey);
  }

  .public_bundle_information_container {
    display: flex;
    flex-direction: row;
    align-items: left;
  }
  .public_bundle_flexbox {
    padding-left: 25px;
  }

  .public_pricing_table_container {
    padding: 30px 10px;
  }

  .public_pricing_overage_disclaimer {
    font-size: 13px;
    margin-right: 20px;
  }

  .public_pricing_order_button a {
    font-size: 16px;
  }

  .pricing_art_4 {
    display: block;
    width: 250px;
    padding-right: 15px;
  }
  .public_orderShippingProcess_container {
    flex-direction: row;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 992px) {
  .public_pricing_bundleSelector_label {
    font-size: 1.7rem;
  }

  .public_pricing_bundleSelector_select {
    font-size: 1.3rem;
  }

  .public_bundle_information_header {
    font-size: 2rem;
  }
  .public_bundle_information_list_item {
    font-size: 1.25rem;
  }
  .public_pricing_headline {
    font-size: 2rem;
    padding: 20px;
  }

  .public_pricing_table_heading {
    font-size: 1.35rem;
  }
  .public_pricing_table_body tr {
    font-size: 1.35rem;
  }
  .public_pricing_overage_disclaimer {
    font-size: 16px;
    margin-right: 25px;
  }

  .public_orderShippingProcess_headline {
    font-size: 2rem;
  }
  .public_orderShippingProcess_list_items {
    font-size: 1.25rem;
  }

  .public_pricing_order_button {
    padding: 15px 10px;
    width: 170px;
  }

  .public_pricing_order_button_top {
    display: block;
    padding: 15px 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--orange);
    width: 150px;
  }

  .public_pricing_order_button_top a {
    font-size: 16px;
  }

  .public_pricing_table_container {
    padding: 30px 10px;
  }

  .public_pricing_order_button a {
    font-size: 16px;
  }

  .pricing_art_4 {
    display: block;
    width: 250px;
    padding-right: 15px;
  }

  .pricing_gears_1 {
    display: block;
    width: 500px;
    margin-top: 32px;
    height: 400px;
    padding-left: 12%;
  }
  .public_orderShippingProcess_container {
    flex-direction: row;
    padding-top: 20px;
  }
  @media only screen and (min-width: 1200px) {
    .public_pricing_bundleSelector_label {
      font-size: 2rem;
      padding-left: 30px;
    }

    .public_pricing_bundleSelector_select {
      font-size: 1.5rem;
    }

    .public_bundle_information_header {
      font-size: 2.35rem;
      margin-top: 30px;
    }
    .public_bundle_information_list_item {
      font-size: 1.5rem;
    }
    .pricing_art_1 {
      display: block;
      width: 400px;
      height: 300px;
      margin-top: 32px;

      /* padding-left: 12%; */
    }
    .public_pricing_headline {
      font-size: 2.35rem;
    }

    .public_pricing_table_heading {
      font-size: 1.5rem;
    }
    .public_pricing_table_body tr {
      font-size: 1.5rem;
    }
    .public_pricing_overage_disclaimer {
      font-size: 18px;
      margin-right: 35px;
    }

    .public_orderShippingProcess_headline {
      font-size: 2rem;
    }
    .public_orderShippingProcess_list_items {
      font-size: 1.5rem;
    }

    .public_pricing_order_button {
      padding: 15px 10px;
      width: 170px;
    }

    .public_pricing_order_button_top {
      display: block;
      padding: 15px 10px;
      border: none;
      border-radius: 5px;
      background-color: var(--orange);
      width: 150px;
    }

    .public_pricing_order_button_top a {
      font-size: 16px;
    }

    .public_pricing_order_button a {
      font-size: 18px;
    }

    .pricing_art_4 {
      width: 350px;
      padding-right: 15px;
    }

    .pricing_gears_1 {
      width: 500px;
      margin-top: 32px;
      height: 400px;
      padding-left: 12%;
    }
    .public_orderShippingProcess_container {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 1400px) {
    .public_pricing_bundleSelector_label {
      font-size: 2rem;
      padding-left: 30px;
    }

    .public_pricing_bundleSelector_select {
      font-size: 1.5rem;
    }

    .public_bundle_information_header {
      font-size: 2.5rem;
    }
    .public_bundle_information_list_item {
      font-size: 1.75rem;
      line-height: 35px;
    }
    .pricing_art_1 {
      width: 600px;
      margin-top: 32px;
      height: 300px;
      padding-left: 12%;
    }
    .pricing_gears_1 {
      width: 500px;
      margin-top: 32px;
      height: 400px;
      padding-left: 12%;
    }

    .public_pricing_order_button_top {
      margin-top: 30px;
      margin-left: 20px;
      padding: 15px 15px;
      width: 200px;
    }

    .public_pricing_order_button_top a {
      font-size: 20px;
    }
    .public_pricing_headline {
      font-size: 2.5rem;
    }

    .public_pricing_table_heading {
      font-size: 1.7rem;
    }
    .public_pricing_table_body tr {
      font-size: 1.7rem;
    }
    .public_pricing_overage_disclaimer {
      font-size: 18px;
      margin-right: 35px;
    }

    .public_orderShippingProcess_headline {
      font-size: 2.5rem;
    }
    .public_orderShippingProcess_list_items {
      font-size: 1.75rem;
    }

    .public_pricing_order_button {
      padding: 15px 15px;
      width: 200px;
    }

    .public_pricing_order_button a {
      font-size: 20px;
    }

    .pricing_art_4 {
      width: 350px;
      padding-right: 15px;
    }
    .public_orderShippingProcess_container {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 1500px) {
    .public_pricing_container {
      padding: 1rem 10%;
    }
  }

  @media only screen and (min-width: 1900px) {
    .public_pricing_bundleSelector_label {
      font-size: 2rem;
      padding-left: 30px;
    }

    .public_pricing_bundleSelector_select {
      font-size: 2rem;
      max-width: 20%;
    }

    .public_bundle_information_header {
      font-size: 2.5rem;
      margin-top: 60px;
    }
    .public_bundle_information_list_item {
      font-size: 1.8rem;
      line-height: 35px;
    }
    .pricing_art_1 {
      width: 650px;
      height: 375px;
      padding-left: 5%;
    }

    .pricing_gears_1 {
      width: 500px;
      margin-top: 32px;
      height: 400px;
      padding-left: 12%;
    }

    .public_pricing_table_container {
      padding-top: 60px;
    }

    .public_pricing_order_button_top {
      margin-top: 30px;
      margin-left: 20px;
      padding: 15px 15px;
      width: 200px;
    }

    .public_pricing_order_button_top a {
      font-size: 20px;
    }
    .public_pricing_headline {
      font-size: 3rem;
    }

    .public_pricing_table_heading {
      font-size: 2rem;
    }
    .public_pricing_table_body tr {
      font-size: 2rem;
    }
    .public_pricing_overage_disclaimer {
      font-size: 18px;
      margin-right: 35px;
    }

    .public_orderShippingProcess_headline {
      font-size: 3rem;
    }
    .public_orderShippingProcess_list_items {
      font-size: 1.5rem;
      line-height: 30px;
    }

    .public_pricing_order_button {
      padding: 20px 20px;
      width: 300px;
    }

    .public_pricing_order_button a {
      font-size: 25px;
    }

    .pricing_art_4 {
      width: 550px;
      padding-right: 15px;
    }
    .public_orderShippingProcess_container {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 2200px) {
    .public_pricing_bundleSelector_label {
      font-size: 2.5rem;
      padding-left: 30px;
    }

    .public_pricing_bundleSelector_select {
      font-size: 2rem;
    }

    .public_bundle_information_header {
      font-size: 3.25rem;
      margin-top: 60px;
    }
    .public_bundle_information_list_item {
      font-size: 2.25rem;
      line-height: 50px;
    }
    .pricing_art_1 {
      width: 700px;
      height: 400px;
      padding-left: 18%;
    }

    .pricing_gears_1 {
      width: 600px;
      margin-top: 22px;
      height: 480px;
      padding-left: 12%;
    }

    .public_pricing_table_container {
      padding-top: 60px;
    }

    .public_pricing_order_button_top {
      margin-top: 30px;
      margin-left: 20px;
      padding: 15px 15px;
      width: 200px;
    }

    .public_pricing_order_button_top a {
      font-size: 20px;
    }
    .public_pricing_headline {
      font-size: 3.25rem;
    }

    .public_pricing_table_heading {
      font-size: 2.25rem;
    }
    .public_pricing_table_body tr {
      font-size: 2.25rem;
    }
    .public_pricing_overage_disclaimer {
      font-size: 22px;
      margin-right: 55px;
    }

    

    .public_orderShippingProcess_headline {
      font-size: 3.25rem;
    }
    .public_orderShippingProcess_list_items {
      font-size: 2.25rem;
      line-height: 60px;
    }

    .public_pricing_order_button {
      padding: 20px 20px;
      width: 300px;
    }

    .public_pricing_order_button a {
      font-size: 25px;
    }

    .pricing_art_4 {
      width: 550px;
      padding-right: 15px;
    }
    .public_orderShippingProcess_container {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 2500px) {
    .public_pricing_bundleSelector_label {
      font-size: 2.5rem;
      padding-left: 30px;
    }

    .public_pricing_bundleSelector_select {
      font-size: 2rem;
    }

    .public_bundle_information_header {
      font-size: 4rem;
      margin-top: 60px;
      margin-left: 5%;
    }
    .public_bundle_information_list_item {
      font-size: 3rem;
      line-height: 70px;
      margin-left: 5%;
    }
    .pricing_art_1 {
      width: 1020px;
      padding-left: 20%;
    }

    .pricing_gears_1 {
      width: 700px;
      margin-top: 32px;
      height: 550px;
      padding-left: 12%;
    }

    .public_pricing_table_container {
      padding-top: 60px;
    }

    .public_pricing_order_button_top {
      margin-top: 30px;
      margin-left: 20px;
      padding: 15px 15px;
      width: 200px;
      margin-left: 5%;
    }

    .public_pricing_order_button_top a {
      font-size: 20px;
    }
    .public_pricing_headline {
      font-size: 4rem;
      margin-left: 2%;
    }

    .public_pricing_table_heading {
      font-size: 3rem;
    }
    .public_pricing_table_body tr {
      font-size: 3rem;
    }
    .public_pricing_overage_disclaimer {
      font-size: 22px;
      margin-right: 55px;
    }

    .public_orderShippingProcess_headline {
      font-size: 4rem;
      margin-left: 2%;
    }
    .public_orderShippingProcess_list_items {
      font-size: 3rem;
      line-height: 80px;
      margin-left: 2%;
    }

    .public_pricing_order_button {
      padding: 20px 20px;
      width: 300px;
    }

    .public_pricing_order_button a {
      font-size: 25px;
    }

    .pricing_art_4 {
      width: 650px;
      padding-right: 15px;
    }
    .public_orderShippingProcess_container {
      padding-top: 20px;
    }
  }
}

.public_solutions_image1,
.public_solutions_image2,
.public_solutions_image3 {
  display: none;
}

.public_solutions_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public_solutions_flexbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public_solutions_header {
  padding: 20px 0;
  max-width: 90%;
  text-align: center;
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 25px;
  color: var(--orange);
}

.public_solutions_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 20px 0;
}

.public_solutions_item_heading {
  color: var(--blue);
  font-weight: 700;
  font-size: 1.1rem;
  padding: 20px 0;
}

.public_solutions_item_body {
  font-size: 0.8rem;
  color: var(--dk_grey);
}

.public_solutions_item_disclaimer {
  font-size: 0.6rem;
  font-weight: 700;
  padding: 10px 0;
}
.public_solutions_cta {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public_solutions_cta_headline {
  color: var(--orange);
  font-weight: 700;
  font-size: 1.5rem;
}

.public_solutions_cta_tagline {
  color: var(--blue);
  font-size: 1.2rem;
  font-weight: 700;
}

.public_solutions_stay_connected_button {
  background-color: var(--orange);
  color: white;
  font-weight: 700;
  padding: 15px;
  border: none;
  border-radius: 5px;
  margin: 10px 0;
}

.public_solutions_stay_connected_button a {
  text-decoration: none;
  font-family: var(--fontC);
  font-weight: 700;
}

@media only screen and (min-width: 768px) {
  .public_solutions_image1,
  .public_solutions_image2,
  .public_solutions_image3 {
    display: block;
  }
  .public_solutions_image1 img {
    height: 200px;
    margin-top: 50%;
  }
  .public_solutions_image2 img {
    width: 150px;
    margin-bottom: 55%;
  }

  .public_solutions_image3 img {
    width: 250px;
    margin-top: 55%;
  }

  .public_solutions_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .public_solutions_flexbox {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 95%;
  }

  .public_solutions_header {
    padding: 20px 5%;
    max-width: 90%;
    text-align: center;
    font-family: var(--fontC);
    font-weight: 700;
    font-size: 25px;
    color: var(--orange);
  }

  .public_solutions_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding: 20px 5%;
  }

  .public_solutions_item_heading {
    color: var(--blue);
    font-weight: 700;
    font-size: 1.1rem;
    padding: 20px 0;
  }

  .public_solutions_item_body {
    font-size: 0.8rem;
    color: var(--dk_grey);
  }

  .public_solutions_item_disclaimer {
    font-size: 0.6rem;
    font-weight: 700;
    padding: 10px 0;
  }
  .public_solutions_cta {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .public_solutions_cta_headline {
    color: var(--orange);
    font-weight: 700;
    font-size: 1.5rem;
  }

  .public_solutions_cta_tagline {
    color: var(--blue);
    font-size: 1.2rem;
    font-weight: 700;
  }

  .public_solutions_stay_connected_button {
    background-color: var(--orange);
    color: white;
    font-weight: 700;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin: 10px 0;
  }
}
@media only screen and (min-width: 992px) {
  .public_solutions_image1,
  .public_solutions_image2,
  .public_solutions_image3 {
    display: block;
  }
  .public_solutions_image1 img {
    height: 200px;
    margin-top: 50%;
  }
  .public_solutions_image2 img {
    width: 250px;
    margin-bottom: 55%;
  }

  .public_solutions_image3 img {
    width: 350px;
    margin-top: 35%;
  }

  .public_solutions_header {
    padding: 25px 5%;
    font-size: 35px;
  }

  .public_solutions_item_heading {
    font-size: 1.5rem;
  }

  .public_solutions_item_body {
    font-size: 1rem;
    color: var(--dk_grey);
  }

  .public_solutions_item_disclaimer {
    font-size: 0.8rem;
  }
  .public_solutions_cta {
    padding: 60px 0;
  }

  .public_solutions_cta_headline {
    font-size: 2rem;
  }

  .public_solutions_cta_tagline {
    font-size: 1.5rem;
  }

  .public_solutions_stay_connected_button {
    background-color: var(--orange);
    color: white;
    font-weight: 700;
    font-size: 18px;
    padding: 10px;
  }
}
@media only screen and (min-width: 1200px) {
  .public_solutions_image1,
  .public_solutions_image2,
  .public_solutions_image3 {
    display: block;
  }
  .public_solutions_image1 img {
    height: 200px;
    margin-top: 50%;
  }
  .public_solutions_image2 img {
    width: 250px;
    margin-bottom: 55%;
  }

  .public_solutions_image3 img {
    width: 350px;
    margin-top: 25%;
  }

  .public_solutions_header {
    padding: 25px 5%;
    font-size: 35px;
  }

  .public_solutions_item_heading {
    font-size: 1.5rem;
  }

  .public_solutions_item_body {
    font-size: 1rem;
    color: var(--dk_grey);
  }

  .public_solutions_item_disclaimer {
    font-size: 0.8rem;
  }
  .public_solutions_cta {
    padding: 60px 0;
  }

  .public_solutions_cta_headline {
    font-size: 2rem;
  }

  .public_solutions_cta_tagline {
    font-size: 1.5rem;
  }

  .public_solutions_stay_connected_button {
    background-color: var(--orange);
    color: white;
    font-weight: 700;
    font-size: 18px;
    padding: 10px;
  }
}
@media only screen and (min-width: 1400px) {
  .public_solutions_image1,
  .public_solutions_image2,
  .public_solutions_image3 {
    display: block;
  }
  .public_solutions_image1 img {
    height: 270px;
    margin-top: 50%;
  }
  .public_solutions_image2 img {
    width: 320px;
    margin-bottom: 25%;
  }

  .public_solutions_image3 img {
    width: 375px;
    margin-top: 20%;
  }

  .public_solutions_header {
    padding: 25px 5%;
    font-size: 35px;
  }

  .public_solutions_item_heading {
    font-size: 2rem;
    padding: 20px 40px;
  }

  .public_solutions_item_body {
    font-size: 1.5rem;
    color: var(--dk_grey);
    padding: 0 35px;
  }

  .public_solutions_item_disclaimer {
    font-size: 1.1rem;
    padding: 0 35px;
  }
  .public_solutions_cta {
    padding: 60px 0;
  }

  .public_solutions_cta_headline {
    font-size: 2.5rem;
  }

  .public_solutions_cta_tagline {
    font-size: 1.5rem;
  }

  .public_solutions_stay_connected_button {
    background-color: var(--orange);
    color: white;
    font-weight: 700;
    font-size: 18px;
    padding: 10px;
  }
}
@media only screen and (min-width: 1500px) {
  .public_solutions_container {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 2500px) {
  .public_solutions_image1,
  .public_solutions_image2,
  .public_solutions_image3 {
    display: block;
  }
  .public_solutions_image1 img {
    height: 370px;
    margin-top: 50%;
  }
  .public_solutions_image2 img {
    width: 450px;
    margin-bottom: 25%;
  }

  .public_solutions_image3 img {
    width: 450px;
    margin-top: 20%;
  }

  .public_solutions_header {
    padding: 25px 5%;
    font-size: 55px;
  }

  .public_solutions_item_heading {
    font-size: 2.5rem;
    padding: 20px 40px;
  }

  .public_solutions_item_body {
    font-size: 2rem;
    color: var(--dk_grey);
    padding: 0 40px;
  }

  .public_solutions_item_disclaimer {
    font-size: 1.5rem;
    padding: 0 35px;
  }
  .public_solutions_cta {
    padding: 60px 0;
  }

  .public_solutions_cta_headline {
    font-size: 3.25rem;
  }

  .public_solutions_cta_tagline {
    font-size: 2.5rem;
  }

  .public_solutions_stay_connected_button {
    background-color: var(--orange);
    color: white;
    font-weight: 700;
    font-size: 25px;
    padding: 15px;
    margin-top: 30px;
  }
}

.public_order_billing_container {
  padding: 1rem;
}

.public_order_headline_billing {
  font-family: var(--fontC);
  color: var(--blue);
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
}

.public_error_message_billing {
  color: red;
  background-color: yellow;
  font-weight: bold;
  text-align: center;
}

.public_order_billing_title {
  color: var(--orange);
  font: var (--fontC);
  font-weight: bold;
  padding: 1.2rem 0 0.8rem 0;
}

.public_order_billing_card_type_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1rem 0;
  color: var(--blue);
  font-size: 0.8rem;
}

#card_type {
  width: 0.8rem;
  height: 0.8rem;
}

.public_billing_info_card {
  display: flex;
  flex-direction: column;
}

.public_billing_info_card label {
  padding: 0.3rem 0;
}
.public_billing_info_card input {
  font-weight: bold;
  border-radius: 5px;
}

.public_billing_date_ccv_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  min-width: 100%;
}

.public_billing_info_expiration {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-evenly;
  padding-left: 10px;
}

.public_billing_info_expiration input {
  width: 40%;
  text-align: right;
}
.public_billing_info_expiration input::-webkit-input-placeholder {
  color: var(--lt_grey);
}
.public_billing_info_expiration input::placeholder {
  color: var(--lt_grey);
}
.public_billing_info_expiration span {
  font-weight: bold;
  padding: 0 0.5rem;
  font-size: 1.2rem;
}

#card_dateB {
  width: 50%;
}
.public_billing_info_ccv_container {
  padding-left: 10px;
}

#card_code {
  margin-left: 0.5rem;
  width: 50%;
}

.public_billing_card_contact {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.public_billing_card_contact label {
  padding: 0.3rem 0;
}
.public_billing_card_contact input {
  border-radius: 5px;
}

.public_order_billing_state_select {
  width: 100%;
}

/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  #card_type {
    width: 1.2rem;
    height: 1.2rem;
  }
}

/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  #card_type {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.public_order_site_summary_container {
  padding: 1rem 1rem 5rem 1rem;
}

.public_order_container h1 {
  text-align: center;
  width: 100%;
}

.site_edit_link {
  color: var(--orange) !important;
  text-decoration: underline;
  font-weight: bold;
}

.error_container {
  width: 100%;
  background-color: yellow;
  text-align: center;
  padding: 1rem;
}
.public_site_summary_error_message {
  background-color: yellow;
  color: red;
  font-weight: bold;
  font-size: 1.2em;
}

.public_order_add_site_button {
  margin-top: 1rem;
  font-size: 0.8rem;
  background: green;
  color: var(--lt_grey);
}

.public_order_site_table {
  margin: 2rem 0;
  width: 100%;
  font-size: 0.9rem;
  border-collapse: collapse;
}

.public_order_site_table thead tr {
  background-color: var(--blue);
  border-color: red;
}

.public_order_site_table tbody tr:nth-child(even) {
  background-color: var(--lt_grey);
}
.public_order_site_table tbody tr:nth-child(odd) {
  background-color: white;
}

.public_order_site_table_heading {
  text-align: center;
  padding: 2px;
  margin: 0;
  color: white;
}

.public_order_site_table_heading span {
  font-size: 0.5em;
  font-weight: normal;
  padding-bottom: 0.3em;
}
.public_order_site_table_body td {
  color: black;
  text-align: center;
  /* border: 1px solid black; */
}

.public_order_site_summary_navigation_buttons {
  margin: 4rem 0 1rem 0;
}
.public_order_site_summary_navigation_buttons button {
  margin-top: 1rem;
  padding: 1.2rem;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  font-family: var(--fontC);
}

/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  .public_order_site_summary_container {
    padding: 2rem 4rem 8rem 4rem !important;
  }

  .public_order_site_summary_navigation_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_order_site_summary_navigation_buttons button {
    padding: 1rem;
    font-size: 1rem;
  }
}
/* *****************************  992  ********************************************************* */
@media only screen and (min-width: 992px) {
  .public_order_site_summary_container {
    padding: 2rem 6rem 8rem 6rem !important;
  }

  .error_container {
    padding: 1rem;
  }
  .public_site_summary_error_message {
    font-size: 1.2em;
  }

  .public_order_add_site_button {
    font-size: 1rem;
  }

  .public_order_site_table_heading {
    font-size: 1.2rem;
  }
  .public_order_site_table_body td {
    font-size: 1.2rem;
  }
  .public_order_totals_table {
    font-size: 1.5rem;
    margin: 4rem 0;
  }
}
/* *****************************  1200  ********************************************************* */
@media only screen and (min-width: 1200px) {
  .public_order_site_summary_container {
    padding: 2rem 8rem 10rem 8rem !important;
  }

  .error_container {
    padding: 1rem;
  }
  .public_site_summary_error_message {
    font-size: 1.2em;
  }

  .public_order_add_site_button {
    font-size: 1rem;
  }

  .public_order_site_table_heading {
    font-size: 1.4rem;
  }
  .public_order_site_table_body td {
    font-size: 1.4rem;
  }
  .public_order_totals_table {
    font-size: 1.5rem;
    margin: 4rem 0 2rem 0;
  }

  .public_order_site_summary_navigation_buttons button {
    padding: 1rem;
    font-size: 1.2rem;
  }
}

/* *****************************  1400  ********************************************************* */
@media only screen and (min-width: 1400px) {
  .public_order_site_summary_container {
    padding: 2rem 14rem 10rem 14rem !important;
  }
}
/* *****************************  1900  ********************************************************* */
@media only screen and (min-width: 1900px) {
  .public_order_site_summary_container {
    padding: 2rem 20rem 10rem 20rem !important;
  }

  .error_container {
    padding: 1rem;
  }
  .public_site_summary_error_message {
    font-size: 1.2em;
  }

  .public_order_add_site_button {
    font-size: 1rem;
  }

  .public_order_site_table_heading {
    font-size: 1.4rem;
  }
  .public_order_site_table_body td {
    font-size: 1.4rem;
  }
  .public_order_totals_table {
    font-size: 1.5rem;
    margin: 4rem 0 2rem 0;
  }

  .public_order_site_summary_navigation_buttons button {
    padding: 1.2rem;
    font-size: 1.5rem;
  }
}
/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  .public_order_site_summary_container {
    padding: 2rem 25rem 10rem 25rem !important;
  }

  .error_container {
    padding: 1rem;
  }
  .public_site_summary_error_message {
    font-size: 1.2em;
  }

  .public_order_add_site_button {
    font-size: 1.2rem;
  }

  .public_order_site_table_heading {
    font-size: 1.4rem;
  }
  .public_order_site_table_body td {
    font-size: 1.4rem;
  }
  .public_order_totals_table {
    font-size: 1.5rem;
    margin: 4rem 0 2rem 0;
  }
  .public_order_site_summary_navigation_buttons button {
    padding: 1.2rem;
    font-size: 1.5rem;
  }
}

/* *****************************  2900  ********************************************************* */
@media only screen and (min-width: 2900px) {
  .public_order_site_summary_container {
    padding: 2rem 30rem 10rem 30rem !important;
  }

  .public_order_site_summary_navigation_buttons button {
    padding: 1.5rem;
    font-size: 1.7rem;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.public_orderProcessing_container {
  background-color: var(--blue);
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 72vh;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.public_orderProcessing_status {
  color: var(--orange);
}

.public_orderProcessing_spinner {
  width: 90px;
  height: 90px;
  position: relative;
  background: rgba(255, 255, 255, 0.13);
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s;
  -webkit-animation-name: animSpin;
          animation-name: animSpin;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes animSpin {
  50% {
    -webkit-transform: rotateZ(180deg) scale(0.94);
            transform: rotateZ(180deg) scale(0.94);
  }
  100% {
    -webkit-transform: rotateZ(360deg) scale(1);
            transform: rotateZ(360deg) scale(1);
  }
}

@keyframes animSpin {
  50% {
    -webkit-transform: rotateZ(180deg) scale(0.94);
            transform: rotateZ(180deg) scale(0.94);
  }
  100% {
    -webkit-transform: rotateZ(360deg) scale(1);
            transform: rotateZ(360deg) scale(1);
  }
}

.public_orderProcessing_spinner,
.public_orderProcessing_spinner:before,
.public_orderProcessing_spinner:after {
  border-radius: 50%;
}

.public_orderProcessing_spinner:before,
.public_orderProcessing_spinner:after {
  content: "";
  position: absolute;
  border: 8px solid transparent;
}

.public_orderProcessing_spinner:before {
  width: 75%;
  height: 75%;
  background: rgba(49, 48, 48, 0.13);
  left: 10%;
  top: 10%;
  border-left: 8px solid rgba(255, 255, 255, 0.34);
  border-bottom: 8px solid rgba(255, 255, 255, 0.34);
}

.public_orderProcessing_spinner:after {
  width: 40%;
  height: 40%;
  left: 30%;
  top: 30%;
  border-right: 8px solid rgba(255, 255, 255, 1);
  border-left: 8px solid rgba(255, 255, 255, 1);
  border-bottom: 8px solid rgba(255, 255, 255, 1);
}

.public_orderComplete_container {
  background-color: var(--lt_grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 72vh;
  margin: 0;
  text-align: center;
}

.public_orderComplete_status {
  color: var(--blue);
  font-size: 1.2rem;
}

/* *****************************  992  ********************************************************* */
@media only screen and (min-width: 992px) {
  .public_orderComplete_status {
    font-size: 2rem;
  }
}

.public_order_site_summary_container {
  padding: 1rem;
}

.public_order_headline_summary {
  font-family: var(--fontC);
  color: var(--blue);
  font-weight: bold;
  font-size: 1.3rem;
  text-align: center;
}

.public_order_summary_title {
  color: var(--orange);
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  padding: 1rem 0;
}

.public_orderSummary_customer_info_table {
  margin-top: 1rem;
  padding: 0.2rem;
  width: 100%;
}

.public_orderSummary_customer_info_table th {
  text-align: left;
  width: 20%;
}

.public_order_site_table_heading th {
  color: white;
}

.public_orderSummary_customer_info_table td {
  text-align: left;
}

.public_orderSummary_billing_information_section {
  margin-top: 1rem;
  padding: 0.2rem;
  width: 100%;
}

.public_order_summary_totals th {
  color: var(--blue);
  font-weight: bold;
}
.public_order_summary_totals td {
  font-weight: bold;
}

.public_orderSummary_billing_information_section th {
  text-align: left;
  width: 40%;
}

.public_orderSummary_billing_information_section td {
  text-align: left;
}

.public_orderSummary_agreement_terms {
  font-family: var(--fontC);
  font-size: 0.8rem;
  padding: 0.3rem;
}

/* *****************************  576  ********************************************************* */
@media only screen and (min-width: 576px) {
  .public_orderSummary_agreement_terms {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}
/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  .public_orderSummary_agreement_terms {
    font-size: 1rem;
    padding: 0.8rem;
  }
}
/* *****************************  1200  ********************************************************* */
@media only screen and (min-width: 1200px) {
  .public_orderSummary_agreement_terms {
    font-size: 1.2rem;
  }
}
/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  .public_orderSummary_agreement_terms {
    font-size: 1.5rem;
  }
}

.public_site_pricing_table_container {
  padding: 1rem;
}

#bundleSelect {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.public_site_pricing_headline {
  color: var(--blue);
  font-family: var(--fontA);
  font-weight: 700;
  font-size: 1rem;
  padding-top: 3%;
  text-align: center;
  margin-bottom: 2rem;
}

.public_pricing_bundle_label {
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0 5px 0 0.8rem;
}

#termSelect {
  margin-bottom: 2rem;
}

.site_bundle_reset {
  color: red;
  text-decoration: underline;
  font-weight: bold;
  margin: 0.2rem 0 0 0.8rem;
  font-size: 0.6rem;
}

.public_pricing_table_heading th {
  color: white;
}
.public_pricing_overage_disclaimer {
  font-size: 0.9rem;
  font-family: var(--fontC);
}

.error_message_container_site {
  text-align: center;
  padding: 1rem;
}

.public_error_message_site_bundle {
  color: red;
  background-color: yellow;
  font-weight: bold;
}

.public_order_site_form_container {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.public_order_add_site_form {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.public_order_add_site_form label {
  text-align: left !important;
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  padding: 0.3rem 0;
}

.public_order_add_state_select {
  width: 100%;
  padding: 0.5rem;
  font-family: var(--fontC);
  color: var(--blue);
  border-radius: 5px;
}

.public_order_add_site_form input {
  min-width: 95%;
  padding: 8px;
  border-color: var(--lt_grey);
  border-radius: 10px;
}

.public_add_site_copy_site_info_button {
  background: green;
  color: var(--lt_grey);
}

.public_order_standard_configuration_description {
  font-family: var(--fontC);
  font-size: 0.9rem;
  padding: 0 0.5rem;
}

.public_order_add_site_config_notes {
  padding: 0.5rem;
  width: 100%;
}

.public_order_add_site_card_title {
  color: var(--orange);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 0.8rem;
  padding: 5% 0 2% 0;
  margin-bottom: 1rem;
}

/* *****************************  576  ********************************************************* */
@media only screen and (min-width: 576px) {
  .public_pricing_bundle_label {
    font-size: 1.5rem;
  }

  .public_site_pricing_headline {
    font-size: 1.2rem;
  }
}
/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  .public_site_pricing_table_container {
    padding: 1.6rem;
  }

  .public_site_pricing_headline {
    font-size: 1.2rem;
  }

  .public_pricing_overage_disclaimer {
    font-size: 1rem;
  }

  .site_bundle_reset {
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-left: 1.2rem;
  }
  .public_add_site_copy_site_info_button {
    width: 35%;
    font-size: 1.1rem;
  }
  .public_order_standard_configuration_description {
    font-family: var(--fontC);
    font-size: 1rem;
    padding: 0 1rem;
  }

  .public_order_add_site_card_title {
    font-size: 1.5rem;
    padding: 1% 0 1% 0;
  }
}

/* *****************************  992  ********************************************************* */
@media only screen and (min-width: 992px) {
  .public_site_pricing_table_container {
    padding: 1.5rem 2rem;
  }

  .public_site_pricing_headline {
    font-size: 1.5rem;
  }

  .public_pricing_bundle_label {
    font-size: 1.7rem;
  }

  .site_bundle_reset {
    font-size: 1rem;
    margin-left: 1.3rem;
  }
}

/* *****************************  1200  ********************************************************* */
@media only screen and (min-width: 1200px) {
  .public_pricing_bundle_label {
    font-size: 2rem;
  }

  .public_order_standard_configuration_description {
    font-size: 1.2rem;
  }
}
/* *****************************  1400  ********************************************************* */
@media only screen and (min-width: 1400px) {
  .public_site_pricing_table_container {
    padding: 1.5rem 10rem;
  }

  .public_site_pricing_headline {
    font-size: 2rem;
  }

  .public_pricing_bundle_label {
    font-size: 2rem;
  }

  .public_add_site_bundle_checkbox {
    height: 0.9rem;
    width: 0.9rem;
  }

  .public_order_add_site_form label {
    font-size: 1.2rem;
  }

  .public_order_add_state_select {
    width: 100%;
    padding: 0.5rem;
    font-family: var(--fontC);
    color: var(--blue);
    font-size: 1.2rem;
  }

  .public_order_add_site_form input {
    min-width: 95%;
    padding: 8px;
    border-color: var(--lt_grey);
  }

  .public_order_standard_configuration_description {
    font-size: 1.2rem;
  }
}

/* *****************************  1900  ********************************************************* */
@media only screen and (min-width: 1900px) {
  .public_site_pricing_table_container {
    padding: 2rem 15rem;
  }

  .public_site_pricing_headline {
    font-size: 3rem;
  }

  .public_pricing_bundle_label {
    font-size: 2.5rem;
  }

  .public_order_add_site_form label {
    font-size: 1.5rem;
  }

  .public_add_site_bundle_checkbox {
    height: 1rem;
    width: 1rem;
  }

  .public_order_add_state_select {
    font-size: 1.5rem;
  }

  .public_order_standard_configuration_description {
    font-size: 1.5rem;
    padding: 1rem 3rem;
  }

  .public_order_add_site_card_title {
    font-size: 2rem;
    padding: 2% 0 2% 0;
    margin-bottom: 1rem;
  }
  .public_order_add_site_config_notes {
    padding: 1rem;
    font-size: 1.5rem;
  }
}

/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  .public_site_pricing_table_container {
    padding: 2rem 20rem;
  }

  .public_add_site_bundle_checkbox {
    height: 1.2rem;
    width: 1.2rem;
  }
}

/* *****************************  2900  ********************************************************* */
@media only screen and (min-width: 2900px) {
  .public_site_pricing_table_container {
    padding: 2rem 25rem;
  }
}

.public_viewSiteOrder_heading {
  color: var(--blue);
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.public_viewSiteOrder_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.public_viewSiteOrder_buttons button {
  margin: 1rem 0;
  padding: 0.8rem;
  font-size: 1rem;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
}

.public_viewSiteOrder_buttons button:first-child {
  background-color: green;
}

.public_viewSiteOrder_buttons button:nth-child(2) {
  background-color: rgb(255, 230, 1);
  color: black;
}

.public_viewSiteOrder_buttons button:last-child {
  background-color: red;
}

.public_viewSiteOrder_section_heading {
  color: var(--orange);
  font-weight: bold;
  font-size: 1.3rem;
}

.public_viewSiteOrder_plan_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.public_viewSiteOrder_table {
  margin: 1rem 0;
  border-collapse: collapse;
}

.public_viewSiteOrder_table th,
.public_viewSiteOrder_table td {
  font-family: var(--fontC);
  font-size: 0.85rem;
  text-align: center;
  padding: 5px;
}

.public_viewSiteOrder_table th {
  background-color: var(--blue);
  color: white;
}
.public_viewSiteOrder_table td {
  background-color: var(--lt_grey);
}

.public_viewSite_site_cost_container {
  padding: 0.5rem;
  margin: 1rem 0;
  border-radius: 5px;
  font-size: 1.2rem;
  font-family: var(--fontC);
}

.public_viewSite_site_cost_container th {
  text-align: left;
  color: var(--blue);
  font-weight: bold;
}
.public_viewSite_site_details_container {
  padding: 0.5rem;
  margin: 1rem 0;
  border-radius: 5px;
  font-size: 1.1rem;
  text-align: left;
}

.public_viewSite_site_details_container table {
  font-family: var(--fontC);
  margin-top: 1.2rem;
}
.public_viewSite_site_details_container th {
  color: var(--blue);
  font-weight: bold;
}
.public_viewSite_site_details_container td {
  padding-left: 0.2rem;
}
.public_viewSite_site_notes_container {
  margin-top: 1.2rem;
}

.public_viewSite_site_notes_container table {
  margin-top: 1rem;
  padding: 0.5rem;
  font-size: 1.1rem;
  font-family: var(--fontC);
  border: 2px solid var(--orange);
  width: 100%;
}

/* *****************************  576  ********************************************************* */
@media only screen and (min-width: 576px) {
  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1rem;
  }
}
/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1.1rem;
  }

  .public_viewSite_site_cost_container {
    font-size: 1.2rem;
  }
  .public_viewSite_site_details_container table {
    font-size: 1.3rem;
  }
  .public_viewSite_site_notes_container table {
    font-size: 1.2rem;
    padding-bottom: 3rem;
  }
}
/* *****************************  992  ********************************************************* */
@media only screen and (min-width: 992px) {
  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1.2rem;
  }

  .public_viewSite_site_cost_container {
    font-size: 1.3rem;
  }
  .public_viewSite_site_details_container table {
    font-size: 1.4rem;
  }
  .public_viewSite_site_notes_container table {
    font-size: 1.3rem;
    padding-bottom: 5rem;
  }
}
/* *****************************  1200  ********************************************************* */
@media only screen and (min-width: 1200px) {
  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1.3rem;
  }

  .public_viewSite_site_cost_container {
    font-size: 1.4rem;
  }
  .public_viewSite_site_details_container table {
    font-size: 1.5rem;
  }
  .public_viewSite_site_notes_container table {
    font-size: 1.4rem;
    padding-bottom: 5rem;
  }
}
/* *****************************  1400  ********************************************************* */
@media only screen and (min-width: 1400px) {
  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1.3rem;
  }

  .public_viewSite_site_cost_container {
    font-size: 1.5rem;
  }
  .public_viewSite_site_details_container table {
    font-size: 1.5rem;
  }
  .public_viewSite_site_notes_container table {
    font-size: 1.4rem;
    padding-bottom: 5rem;
  }
}
/* *****************************  1900  ********************************************************* */
@media only screen and (min-width: 1900px) {
  .public_viewSiteOrder_heading {
    font-size: 2.2rem;
    padding: 3rem 0;
  }

  .public_viewSiteOrder_section_heading {
    font-size: 1.6rem;
  }

  .public_viewSiteOrder_buttons button {
    font-size: 1.5rem;
  }

  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1.5rem;
  }

  .public_viewSite_site_cost_container {
    font-size: 1.8rem;
  }
  .public_viewSite_site_details_container table {
    font-size: 1.7rem;
  }
  .public_viewSite_site_notes_container table {
    font-size: 1.6rem;
    padding-bottom: 5rem;
  }
}
/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  .public_viewSiteOrder_heading {
    font-size: 3rem;
    padding: 4rem 0;
  }

  .public_viewSiteOrder_section_heading {
    font-size: 1.8rem;
  }

  .public_viewSiteOrder_table th,
  .public_viewSiteOrder_table td {
    font-size: 1.8rem;
  }

  .public_viewSite_site_cost_container {
    font-size: 2rem;
  }
  .public_viewSite_site_details_container table {
    font-size: 1.9rem;
  }
  .public_viewSite_site_notes_container table {
    font-size: 1.8rem;
    padding-bottom: 5rem;
  }
}
/* *****************************  2900  ********************************************************* */
@media only screen and (min-width: 2900px) {
}

.public_site_edit_site_container {
  padding: 1rem;
}

.public_edit_order_bundle_button {
  margin-top: 1rem;
}

.public_edit_order_bundle_button button {
  background-color: var(--orange);
  color: white;
  margin-left: 0.3rem;
}

.public_edit_site_form {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.public_edit_site_form label {
  text-align: left !important;
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  padding: 0.3rem 0;
}

.public_order_edit_state_select {
  width: 100%;
  padding: 0.5rem;
  font-family: var(--fontC);
  color: var(--blue);
  border-radius: 5px;
}

.public_edit_site_form input {
  min-width: 95%;
  border-color: var(--lt_grey);
  border-radius: 10px;
  font-family: var(--fontC);
}

.public_edit_site_card_title {
  color: var(--orange);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 0.8rem;
  padding: 5% 0 2% 0;
  margin-bottom: 1rem;
}

.public_edit_section_card {
  background-color: var(--lt_grey);
  margin: 1rem 0;
  padding: 1.3rem 1.3rem 3rem 1.3rem;
  border: none;
  border-radius: 5px;
  width: 100%;
}

.public_edit_section_card textarea {
  padding: 0.8rem;
  width: 100%;
}

.site_bundle_reset_edit {
  color: red;
  text-decoration: underline;
  font-weight: bold;
  margin: 0.2rem 0 0 0.8rem;
  font-size: 0.8rem;
}

.public_edit_site_copy_billing_button {
  background-color: var(--orange);
  color: white;
  font-size: 0.9rem;
}

.public_order_container {
  padding: 1rem 1rem 5rem 1rem;
}
.public_order_contacts_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem;
}

.public_order_customer_information_desktop {
  display: none;
}

.public_order_headline {
  color: var(--blue);
  font-family: var(--fontA);
  font-weight: 700;
  font-size: 0.8rem;
  padding-top: 3%;
  text-align: center;
}

.public_order_section_card {
  background-color: var(--lt_grey);
  margin: 1rem 0;
  padding: 30px 20px;
  border: none;
  border-radius: 5px;
  width: 100%;
}
.public_order_referral {
  font-family: var(--fontC);
  font-size: 0.85rem;
}

.public_order_form {
  color: var(--blue);
  font-family: var(--fontC);
}

.public_order_section_card_title {
  color: var(--orange);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 0.8rem;
  padding: 5% 0 2% 0;
  margin-bottom: 1rem;
}

.public_order_form_text_input {
  color: var(--blue);
  min-width: 90%;
  border-color: var(--blue);
  border-radius: 5px;
  margin: 0.5rem 0;
  width: 100%;
}

.public_order_section_state_select {
  width: 100%;
  margin: 1rem 0;
  padding: 0.5rem;
  font-family: var(--fontC);
  color: var(--blue);
}

.public_order_form_label {
  padding-left: 0.1rem;
  padding-top: 2rem;
}

.public_order_navigation_buttons {
  width: 100%;
}

.public_order_navigation_buttons button {
  margin-top: 1em;
  padding: 1.2rem;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  font-family: var(--fontC);
}

.order_cancel {
  background-color: red;
  border: none;
  border-radius: 5px;
  width: 100%;
}
.order_next {
  background-color: var(--blue);
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 1rem;
}

.public_error_message {
  color: red;
  background: yellow;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 5px;
  margin-left: 2px;
}

/* *****************************  576  ********************************************************* */
@media only screen and (min-width: 576px) {
  .public_order_headline {
    font-size: 1.2rem;
    padding-top: 5%;
  }

  .public_order_section_card {
    padding: 2rem 1.5rem;
  }

  .public_order_referral {
    font-size: 1rem;
  }

  .public_order_section_card_title {
    font-size: 1rem;
  }

  .public_order_section_state_select {
    font-size: 1rem;
  }

  .public_order_navigation_buttons {
    width: 100%;
  }

  .public_order_navigation_buttons button {
    margin-top: 1em;
    padding: 1.2rem;
    font-size: 1rem;
  }

  .order_cancel {
    width: 100%;
  }
  .order_next {
    width: 100%;
  }

  .public_error_message {
    font-weight: bold;
  }

  .public_order_add_site_button {
    margin-top: 1rem;
    border-radius: 5px;
    background: green;
    color: var(--lt_grey);
  }

  .public_order_site_table {
    margin: 2rem 0;
    width: 100%;
    border: 1px black solid;
    font-size: 0.9rem;
  }

  .public_order_site_table thead tr {
    border: 1px black solid;
  }

  .public_order_site_table_heading {
    text-align: center;
    padding: 0;
    margin: 0;
  }
}

/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  .public_order_container {
    padding: 2rem 4rem 8rem 4rem !important;
  }

  .public_order_headline {
    font-size: 2rem;
  }

  .public_order_section_card_title {
    font-size: 1.5rem;
  }

  .public_order_navigation_buttons {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_order_navigation_buttons button {
    padding: 1rem 0.5rem;
    font-size: 1.2rem;
  }

  .order_cancel {
    width: 40%;
  }
  .order_next {
    width: 40%;
    margin-bottom: 0;
  }

  .public_error_message {
    font-weight: bold;
    font-size: 1.2rem;
  }
}
/* *****************************  992  ********************************************************* */
@media only screen and (min-width: 992px) {
  .public_order_container {
    padding: 2rem 6rem 8rem 6rem !important;
  }
  .public_order_customer_information_mobile {
    display: none;
  }
  .public_order_customer_information_desktop {
    display: block;
  }

  .public_order_headline {
    padding-top: 5%;
  }

  .public_order_section_card {
    padding: 2rem 1.5rem;
  }

  .public_order_referral {
    font-size: 1rem;
  }

  .public_order_section_card_title {
    font-size: 1.2rem;
  }

  .public_order_section_state_select {
    font-size: 1rem;
  }

  .public_order_form_label_group {
    width: 100%;
  }

  .public_order_customer_information_desktop_labels {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_labels :first-child {
    min-width: 55%;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :nth-child(2) {
    min-width: 21%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :last-child {
    min-width: 20%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_inputs {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_inputs :first-child {
    min-width: 55%;
  }
  .public_order_customer_information_desktop_inputs :nth-child(2) {
    min-width: 5%;
    margin: 0.5rem 0 0.5rem 1rem;
    border-radius: 5px;
  }
  .public_order_customer_information_desktop_inputs :nth-child(3) {
    min-width: 20%;
    margin-left: 1rem;
  }
  .public_order_contact_flex {
    display: flex;
    flex-direction: row;
  }
  .contact_card {
    width: 60%;
    margin-left: 1rem;
    padding: 1.5rem 1rem;
  }

  .public_order_navigation_buttons {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_order_navigation_buttons button {
    margin-top: 1em;
    padding: 1rem 0.5rem;
    font-size: 1.2rem;
  }

  .order_cancel {
    width: 30%;
  }
  .order_next {
    width: 30%;
    margin-bottom: 0;
  }

  .public_error_message {
    font-weight: bold;
    font-size: 1.2rem;
  }
}
/* *****************************  1200  ********************************************************* */
@media only screen and (min-width: 1200px) {
  .public_order_container {
    padding: 2rem 8rem 10rem 8rem !important;
  }

  .public_order_headline {
    font-size: 2rem;
  }

  .public_order_referral {
    font-size: 1.2rem;
  }

  .public_order_section_card_title {
    font-size: 1.5rem;
  }

  .public_order_section_state_select {
    font-size: 1rem;
  }

  .public_order_form_label_group {
    width: 100%;
  }

  .public_order_navigation_buttons button {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    font-size: 1.2rem;
  }

  .order_cancel {
    width: 25%;
  }
  .order_next {
    width: 25%;
  }
}
/* *****************************  1400  ********************************************************* */
@media only screen and (min-width: 1400px) {
  .public_order_container {
    padding: 2rem 20rem 10rem 20rem !important;
  }

  .public_order_headline {
    padding-top: 5%;
  }

  .public_order_section_card {
    padding: 2rem 1.5rem;
  }

  .public_order_section_card_title {
    font-size: 1.5rem;
  }

  .public_order_navigation_buttons button {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  .order_cancel {
    width: 25%;
  }
  .order_next {
    width: 25%;
  }
}
/* *****************************  1900  ********************************************************* */
@media only screen and (min-width: 1900px) {
  .public_order_container {
    padding: 2rem 30rem 10rem 30rem !important;
  }

  .public_order_headline {
    font-size: 2rem;
    padding-top: 5%;
  }

  .public_order_section_card {
    padding: 2rem 1.5rem;
  }

  .public_order_referral {
    font-size: 1.2rem;
  }

  .public_order_section_card_title {
    font-size: 1.5rem;
  }

  .public_order_section_state_select {
    font-size: 1rem;
  }

  .public_order_form_label_group {
    width: 100%;
  }

  .public_order_customer_information_desktop_labels {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_labels :first-child {
    min-width: 55%;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :nth-child(2) {
    min-width: 21%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :last-child {
    min-width: 20%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_inputs {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_inputs :first-child {
    min-width: 55%;
  }
  .public_order_customer_information_desktop_inputs :nth-child(2) {
    min-width: 5%;
    margin: 0.5rem 0 0.5rem 1rem;
    border-radius: 5px;
  }
  .public_order_customer_information_desktop_inputs :nth-child(3) {
    min-width: 20%;
    margin-left: 1rem;
  }
  .public_order_contact_flex {
    display: flex;
    flex-direction: row;
  }
  .contact_card {
    width: 60%;
    margin-left: 1rem;
    padding: 1.5rem 1rem;
  }

  .public_order_navigation_buttons {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_order_navigation_buttons button {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    padding: 1rem 0.5rem;
    font-size: 1.2rem;
  }

  .order_cancel {
    width: 25%;
  }
  .order_next {
    width: 25%;
  }

  .public_error_message {
    font-weight: bold;
    font-size: 1.2rem;
  }
}
/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  .public_order_container {
    padding: 2rem 40rem 10rem 40rem !important;
  }

  .public_order_headline {
    font-size: 3rem;
    padding-top: 5%;
  }

  .public_order_section_card {
    padding: 2rem 1.5rem;
  }

  .public_order_referral {
    font-size: 1.5rem;
  }

  .public_order_section_card_title {
    font-size: 2rem;
  }

  .public_order_form_text_input {
    font-size: 1.2rem;
  }

  .public_order_section_state_select {
    font-size: 1.2rem;
  }

  .public_order_form_label_group {
    width: 100%;
  }

  .public_order_customer_information_desktop_labels {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_labels :first-child {
    min-width: 55%;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :nth-child(2) {
    min-width: 21%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :last-child {
    min-width: 20%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_inputs {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_inputs :first-child {
    min-width: 55%;
  }
  .public_order_customer_information_desktop_inputs :nth-child(2) {
    min-width: 5%;
    margin: 0.5rem 0 0.5rem 1rem;
    border-radius: 5px;
  }
  .public_order_customer_information_desktop_inputs :nth-child(3) {
    min-width: 20%;
    margin-left: 1rem;
  }
  .public_order_contact_flex {
    display: flex;
    flex-direction: row;
  }
  .contact_card {
    width: 60%;
    margin-left: 1rem;
    padding: 1.5rem 1rem;
  }

  .public_order_navigation_buttons {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_order_navigation_buttons button {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    padding: 1rem 0.5rem;
    font-size: 1.2rem;
  }

  .order_cancel {
    width: 25%;
  }
  .order_next {
    width: 25%;
  }

  .public_error_message {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .public_order_headline {
    font-size: 3rem;
    padding-top: 5%;
  }

  .public_order_section_card {
    padding: 3rem 3rem;
  }

  .public_order_referral {
    font-size: 1.5rem;
  }

  .public_order_section_card_title {
    font-size: 2rem;
  }

  .public_order_section_state_select {
    font-size: 1.2rem;
  }

  .public_order_form_label_group {
    width: 100%;
  }

  .public_order_customer_information_desktop_labels {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_labels :first-child {
    min-width: 55%;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :nth-child(2) {
    min-width: 21%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :last-child {
    min-width: 20%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_inputs {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_inputs :first-child {
    min-width: 55%;
  }
  .public_order_customer_information_desktop_inputs :nth-child(2) {
    min-width: 5%;
    margin: 0.5rem 0 0.5rem 1rem;
    border-radius: 5px;
  }
  .public_order_customer_information_desktop_inputs :nth-child(3) {
    min-width: 20%;
    margin-left: 1rem;
  }
  .public_order_contact_flex {
    display: flex;
    flex-direction: row;
  }
  .contact_card {
    width: 60%;
    margin-left: 1rem;
    padding: 2rem 3rem 4rem 3rem;
  }

  .public_order_navigation_buttons {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_order_navigation_buttons button {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    padding: 2rem 1rem;
    font-size: 2rem;
  }

  .order_cancel {
    width: 20%;
  }
  .order_next {
    width: 20%;
  }

  .public_error_message {
    font-weight: bold;
    font-size: 1.2rem;
  }
}

/* *****************************  2900  ********************************************************* */
@media only screen and (min-width: 2900px) {
  .public_order_container {
    padding: 2rem 50rem 10rem 50rem !important;
  }
}

.public_header_container {
  background-color: var(--blue);
  min-height: 130px;
}

.public_header_heading {
  color: var(--lt_grey);
}

.public_header_logo {
  display: flex;
  justify-content: center;
}

.public_header_logo img {
  width: 167px;
  height: 99px;
}

.public_header_tagline {
  color: var(--orange);
  font-family: var(--fontC);
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}

.public_header_mobile_menu_expanded {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: auto;
  background-color: var(--lt_grey);
  border-radius: 5% 0 0 5%;
}

.public_header_mobile_menu_exit_icon {
  color: var (--blue);
  font-size: 30px;
  font-weight: bolder;
  text-align: right;
  margin: 1rem;
}

.public_header_mobile_menu_icon {
  position: absolute;
  top: 5px;
  right: 10px;
}

.public_header_mobile_menu_icon_line {
  width: 35px;
  height: 3px;
  background-color: var(--dk_grey);
  margin: 6px 0;
}

.public_header_mobile_menu ul {
  list-style: none;
}

.public_header_mobile_menu_items {
  margin-top: 30px;
  margin-right: 20px;
  text-align: right;
}

.public_header_mobile_menu_items a {
  text-decoration: none;
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: bold;
  font-size: 25px;
}

.public_header_mobile_menu ul :last-child {
  margin-bottom: 30px;
}

.public_header_desktop_menu {
  display: none;
}

.CurrentPage {
  color: var(--orange) !important;
}

@media only screen and (min-width: 768px) {
  .public_header_desktop_menu {
    display: block;
  }

  .public_header_mobile_menu_icon {
    display: none;
  }
  .public_header_logo {
    display: flex;
    justify-content: left;
  }

  .public_header_logo img {
    margin-top: 1rem;
    margin-left: 5%;
    width: 190px;
    height: auto;
  }

  .public_header_desktop_menu ul {
    list-style: none;
    display: flex;
    flex-direction: row;
  }
  .public_header_desktop_flexbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_header_desktop_menu_items {
    margin-top: 30px;
    margin-right: 20px;
    text-align: right;
  }

  .public_header_desktop_menu_items a {
    text-decoration: none;
    color: var(--lt_grey);
    font-family: var(--fontC);
    font-weight: bold;
    font-size: 1.2rem;
  }

  .public_header_desktop_menu_items a:hover {
    color: var(--orange);
    cursor: pointer;
  }

  .public_header_tagline {
    font-size: 1.2rem;
    text-align: left;
    padding-left: 20px;
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .public_header_logo img {
    width: 225px;
    margin-left: 25%;
  }

  .public_header_tagline {
    font-size: 1.5rem;
    margin-left: 4%;
  }

  .public_header_desktop_menu_items {
    margin-right: 30px;
  }

  .public_header_desktop_menu_items a {
    font-size: 1.4rem;
  }
}

@media only screen and (min-width: 1400px) {
  .public_header_logo img {
    width: 220px;
  }

  .public_header_tagline {
    font-size: 1.2rem;
  }

  .public_header_desktop_menu_items a {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1900px) {
  .public_header_logo img {
    width: 220px;
    margin-left: 35%;
  }

  .public_header_desktop_menu_items a {
    font-size: 1.8rem;
  }
}
@media only screen and (min-width: 2500px) {
  .public_header_logo img {
    width: 325px;
    margin-left: 38%;
  }

  .public_header_tagline {
    font-size: 2rem;
    margin-left: 4%;
  }

  .public_header_desktop_menu_items {
    font-size: 2.5rem;
    margin-right: 45px;
  }

  .public_header_desktop_menu_items a {
    font-size: 3rem;
  }
}

.public_footer_container {
  background-color: var(--blue);
  min-height: 100px;
  padding: 0;
  margin: 0;
}

.public_header_mobile_flexbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.public_header_mobile_flexbox img {
  width: 150px;
}

.public_footer_contact_info ul {
  list-style: none;
  margin-right: 10px;
}

.public_footer_contact_info_items {
  font-family: var(--fontB);
  font-weight: 700;
  color: var(--orange);
  font-size: 10px;
  text-align: right;
}
.public_footer_contact_info_items span {
  color: var(--lt_grey);
  font-weight: 400;
}
@media only screen and (min-width: 576px) {
  .public_header_mobile_flexbox img {
    width: 200px;
  }
  .public_footer_contact_info_items {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) {
  .public_header_mobile_flexbox img {
    width: 220px;
  }
  .public_footer_contact_info_items {
    font-size: 18px;
  }
}
@media only screen and (min-width: 992px) {
  .public_header_mobile_flexbox img {
    width: 245px;
    margin-left: 5%;
  }
  .public_footer_contact_info_items {
    font-size: 18px;
    margin-right: 25px;
  }
}
@media only screen and (min-width: 1200px) {
  .public_header_mobile_flexbox img {
    width: 275px;
  }
  .public_footer_contact_info_items {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1400px) {
  .public_header_mobile_flexbox img {
    width: 325px;
  }
  .public_footer_contact_info_items {
    font-size: 25px;
  }
}
@media only screen and (min-width: 1900px) {
  .public_header_mobile_flexbox img {
    width: 350px;
  }
  .public_footer_contact_info_items {
    font-size: 28px;
  }
}
@media only screen and (min-width: 2500px) {
  .public_header_mobile_flexbox img {
    width: 375px;
  }
  .public_footer_contact_info_items {
    font-size: 32px;
    margin-right: 35px;
  }
}

.App {
  /* --fontA: "Montserrat", sans-serif; */
  --fontA: "Montserrat", sans-serif;
  --fontB: "Source Sans Pro", sans-serif;
  /* --fontC: "'Noto Sans JP', sans-serif"; */
  --fontC: "Roboto", sans-serif;
  --blue: #2e66b0;
  --lt_grey: #e2e2e2;
  --dk_grey: #acacac;
  --orange: #f5780a;
  --orangeHover: #f3a159;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.title {
  font-family: var(--fontA);
  font-size: 25px;
  color: var(--orange);
}

#backDrop {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

h1 {
  color: var(--blue);
  font-weight: bold;
  font-size: 1.2rem;
  font-family: var(--fontC);
}

h2 {
  color: var(--orange);
  font-weight: bold;
  font-size: 1rem;
  font-family: var(--fontC);
}

button {
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-family: var(--fontC);
  cursor: pointer;
}

label {
  color: var(--blue);
  font-size: 0.8rem;
  font-weight: bold;
}

input {
  font-size: 0.8rem;
  padding: 0.5rem;
  color: var(--blue);
  border: 1px solid var(--blue);
}

select {
  font-size: 0.8rem;
  padding: 0.5rem;
  color: var(--blue);
  border: 1px solid var(--blue);
  font-family: var(--fontC);
  border-radius: 5px;
}

th {
  color: var(--blue);
  font-size: 0.8rem;
  font-weight: bold;
  font-family: var(--fontC);
}

td {
  font-size: 0.8rem;
  color: var(--blue);
  font-family: var(--fontC);
}

textarea {
  font-size: 1rem;
  color: var(--blue);
  border: 1px solid var(--blue);
}

/* *****************************  576  ********************************************************* */
@media only screen and (min-width: 576px) {
  h1 {
    font-size: 1.3rem;
  }

  h2 {
    font-size: 1.1rem;
  }

  button {
    padding: 0.8rem;
  }
}
/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  label {
    font-size: 1rem;
  }
  input {
    font-size: 1rem;
  }
  select {
    font-size: 1rem;
  }
}
/* *****************************  992  ********************************************************* */
@media only screen and (min-width: 992px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.3rem;
  }
}
/* *****************************  1200  ********************************************************* */
@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 1.7rem;
  }

  h2 {
    font-size: 1.4rem;
  }
}
/* *****************************  1400  ********************************************************* */
@media only screen and (min-width: 1400px) {
  h1 {
    font-size: 1.9rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  textarea {
    font-size: 1.2rem;
  }
}
/* *****************************  1900  ********************************************************* */
@media only screen and (min-width: 1900px) {
  h1 {
    font-size: 2.1rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  button {
    padding: 1rem;
  }

  th {
    font-size: 1.2rem;
  }

  td {
    font-size: 1.2rem;
  }
}
/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  label {
    font-size: 1.5rem;
  }
  input {
    font-size: 1.5rem;
  }
  select {
    font-size: 1.5rem;
  }

  th {
    font-size: 1.5rem;
  }

  td {
    font-size: 1.5rem;
  }
}
/* *****************************  2900  ********************************************************* */
@media only screen and (min-width: 2900px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.1rem;
  }
}

