.public_whatsincluded_container {
  margin: 0;
  padding: 0;
}

.public_whatsincluded_image {
  display: none;
}

.public_whatsincluded_headline {
  color: var(--orange);
  font-family: var(--fontA);
  font-weight: 700;
  text-align: center;
  font-size: 1.5rem;
  padding: 40px 20px;
}

.public_whatsincluded_card {
  /* background-color: var(--lt_grey); */
  padding: 10px 20px;
}

.public_whatsincluded_card_headline {
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 1.3rem;
}

.public_whatsincluded_card_body {
  font-family: var(--fontC);
  font-size: 0.9rem;
  color: var(--dk_grey);
}

.public_whatsincluded_ul_no_decoration {
  list-style: none;
}
.public_whatsincluded_description_nolist {
  margin: 0;
  padding: 0 10px;
}

.public_whatsincluded_button {
  text-align: center;
  padding: 0px 0px 20px 0px;
}

.public_whatsincluded_button button {
  background-color: var(--orange);
  border: none;
  border-radius: 5px;
  padding: 12px;
  color: var(--lt_grey);
  font-size: 1rem;
  font-family: var(--fontC);
}
.public_whatsincluded_button button a {
  text-decoration: none;
  color: var(--lt_grey);
  font-size: 1rem;
  font-family: var(--fontC);
}

.public_whatsincluded_button button:hover {
  background-color: var(--orangeHover);
  color: black;
}
.public_whatsincluded_installation_card {
  background-color: var(--blue);
  border-radius: 5px;
  margin: 20px;
}

.public_whatsincluded_installation_headline {
  color: var(--lt_grey);
  font-weight: 700;
  font-family: var(--fontB);
  padding: 10px 0px;
  font-size: 18px;
  text-align: center;
}

.public_whatsincluded_installation_body {
  color: var(--lt_grey);
  font-family: var(--fontB);
  padding: 0px 10px 20px 10px;
  font-size: 15px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .public_whatsincluded_headline {
    font-size: 2rem;
  }

  .public_whatsincluded_card_headline {
    font-size: 1.5rem;
  }

  .public_whatsincluded_card_body {
    font-size: 1.2rem;
  }

  .public_whatsincluded_installation_headline {
    font-size: 22px;
  }

  .public_whatsincluded_installation_body {
    padding: 0px 20px 30px 20px;
    font-size: 18px;
  }

  .public_whatsincluded_button button {
    padding: 15px;
    font-size: 1.2rem;
  }
  .public_whatsincluded_button button a {
    font-size: 1.2rem;
  }
}
@media only screen and (min-width: 1200px) {
  .public_whatsincluded_container {
    padding: 0 3%;
  }

  .public_whatsincluded_cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_whatsincluded_headline {
    font-size: 2rem;
  }

  .public_whatsincluded_card_headline {
    text-align: center;
    font-size: 1.65rem;
  }

  .public_whatsincluded_card_body {
    font-size: 1.3rem;
  }

  .public_whatsincluded_installation_card {
    padding: 10px;
    width: 600px;
    margin-right: 5%;
  }

  .public_whatsincluded_installation_headline {
    font-size: 40px;
  }

  .public_whatsincluded_installation_body {
    font-size: 25px;
  }

  .public_whatsincluded_button button {
    padding: 15px;
    font-size: 1rem;
  }
  .public_whatsincluded_button button a {
    font-size: 1rem;
  }
  .public_whatsincluded_image {
    display: block;
    margin-bottom: 20px;
    margin-left: 40px;
    max-height: 100px;
  }
  .public_whatsincluded_image img {
    max-height: 250px;
  }
  .public_whatsincluded_flexbox {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width: 1400px) {
  .public_whatsincluded_installation_card {
    margin-right: 15%;
  }
}

@media only screen and (min-width: 1500px) {
  .public_whatsincluded_container {
    padding: 0 5%;
  }
}
@media only screen and (min-width: 1900px) {
  .public_whatsincluded_headline {
    font-size: 3rem;
  }

  .public_whatsincluded_card_headline {
    font-size: 2rem;
  }

  .public_whatsincluded_installation_card {
    padding: 30px;
  }

  .public_whatsincluded_installation_headline {
    font-size: 35px;
  }

  .public_whatsincluded_installation_body {
    padding: 0px 20px 30px 20px;
    font-size: 20px;
  }

  .public_whatsincluded_button button {
    padding: 15px;
    font-size: 1.5rem;
  }
  .public_whatsincluded_button button a {
    font-size: 1.2rem;
  }

  .public_whatsincluded_flexbox {
    margin-top: 20px;
  }

  .public_whatsincluded_installation_card {
    padding: 10px;
    width: 600px;
    margin-right: 32%;
  }
}

@media only screen and (min-width: 2500px) {
  .public_whatsincluded_installation_card {
    margin-right: 50%;
  }

  .public_whatsincluded_headline {
    font-size: 4rem;
  }

  .public_whatsincluded_card_headline {
    font-size: 3.5rem;
    padding-bottom: 40px;
  }

  .public_whatsincluded_card_body {
    font-size: 2rem;
    line-height: 50px;
  }

  .public_whatsincluded_image img {
    max-height: 300px;
  }
}
