.public_site_pricing_table_container {
  padding: 1rem;
}

#bundleSelect {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.public_site_pricing_headline {
  color: var(--blue);
  font-family: var(--fontA);
  font-weight: 700;
  font-size: 1rem;
  padding-top: 3%;
  text-align: center;
  margin-bottom: 2rem;
}

.public_pricing_bundle_label {
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0 5px 0 0.8rem;
}

#termSelect {
  margin-bottom: 2rem;
}

.site_bundle_reset {
  color: red;
  text-decoration: underline;
  font-weight: bold;
  margin: 0.2rem 0 0 0.8rem;
  font-size: 0.6rem;
}

.public_pricing_table_heading th {
  color: white;
}
.public_pricing_overage_disclaimer {
  font-size: 0.9rem;
  font-family: var(--fontC);
}

.error_message_container_site {
  text-align: center;
  padding: 1rem;
}

.public_error_message_site_bundle {
  color: red;
  background-color: yellow;
  font-weight: bold;
}

.public_order_site_form_container {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.public_order_add_site_form {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.public_order_add_site_form label {
  text-align: left !important;
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  padding: 0.3rem 0;
}

.public_order_add_state_select {
  width: 100%;
  padding: 0.5rem;
  font-family: var(--fontC);
  color: var(--blue);
  border-radius: 5px;
}

.public_order_add_site_form input {
  min-width: 95%;
  padding: 8px;
  border-color: var(--lt_grey);
  border-radius: 10px;
}

.public_add_site_copy_site_info_button {
  background: green;
  color: var(--lt_grey);
}

.public_order_standard_configuration_description {
  font-family: var(--fontC);
  font-size: 0.9rem;
  padding: 0 0.5rem;
}

.public_order_add_site_config_notes {
  padding: 0.5rem;
  width: 100%;
}

.public_order_add_site_card_title {
  color: var(--orange);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 0.8rem;
  padding: 5% 0 2% 0;
  margin-bottom: 1rem;
}

/* *****************************  576  ********************************************************* */
@media only screen and (min-width: 576px) {
  .public_pricing_bundle_label {
    font-size: 1.5rem;
  }

  .public_site_pricing_headline {
    font-size: 1.2rem;
  }
}
/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  .public_site_pricing_table_container {
    padding: 1.6rem;
  }

  .public_site_pricing_headline {
    font-size: 1.2rem;
  }

  .public_pricing_overage_disclaimer {
    font-size: 1rem;
  }

  .site_bundle_reset {
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-left: 1.2rem;
  }
  .public_add_site_copy_site_info_button {
    width: 35%;
    font-size: 1.1rem;
  }
  .public_order_standard_configuration_description {
    font-family: var(--fontC);
    font-size: 1rem;
    padding: 0 1rem;
  }

  .public_order_add_site_card_title {
    font-size: 1.5rem;
    padding: 1% 0 1% 0;
  }
}

/* *****************************  992  ********************************************************* */
@media only screen and (min-width: 992px) {
  .public_site_pricing_table_container {
    padding: 1.5rem 2rem;
  }

  .public_site_pricing_headline {
    font-size: 1.5rem;
  }

  .public_pricing_bundle_label {
    font-size: 1.7rem;
  }

  .site_bundle_reset {
    font-size: 1rem;
    margin-left: 1.3rem;
  }
}

/* *****************************  1200  ********************************************************* */
@media only screen and (min-width: 1200px) {
  .public_pricing_bundle_label {
    font-size: 2rem;
  }

  .public_order_standard_configuration_description {
    font-size: 1.2rem;
  }
}
/* *****************************  1400  ********************************************************* */
@media only screen and (min-width: 1400px) {
  .public_site_pricing_table_container {
    padding: 1.5rem 10rem;
  }

  .public_site_pricing_headline {
    font-size: 2rem;
  }

  .public_pricing_bundle_label {
    font-size: 2rem;
  }

  .public_add_site_bundle_checkbox {
    height: 0.9rem;
    width: 0.9rem;
  }

  .public_order_add_site_form label {
    font-size: 1.2rem;
  }

  .public_order_add_state_select {
    width: 100%;
    padding: 0.5rem;
    font-family: var(--fontC);
    color: var(--blue);
    font-size: 1.2rem;
  }

  .public_order_add_site_form input {
    min-width: 95%;
    padding: 8px;
    border-color: var(--lt_grey);
  }

  .public_order_standard_configuration_description {
    font-size: 1.2rem;
  }
}

/* *****************************  1900  ********************************************************* */
@media only screen and (min-width: 1900px) {
  .public_site_pricing_table_container {
    padding: 2rem 15rem;
  }

  .public_site_pricing_headline {
    font-size: 3rem;
  }

  .public_pricing_bundle_label {
    font-size: 2.5rem;
  }

  .public_order_add_site_form label {
    font-size: 1.5rem;
  }

  .public_add_site_bundle_checkbox {
    height: 1rem;
    width: 1rem;
  }

  .public_order_add_state_select {
    font-size: 1.5rem;
  }

  .public_order_standard_configuration_description {
    font-size: 1.5rem;
    padding: 1rem 3rem;
  }

  .public_order_add_site_card_title {
    font-size: 2rem;
    padding: 2% 0 2% 0;
    margin-bottom: 1rem;
  }
  .public_order_add_site_config_notes {
    padding: 1rem;
    font-size: 1.5rem;
  }
}

/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  .public_site_pricing_table_container {
    padding: 2rem 20rem;
  }

  .public_add_site_bundle_checkbox {
    height: 1.2rem;
    width: 1.2rem;
  }
}

/* *****************************  2900  ********************************************************* */
@media only screen and (min-width: 2900px) {
  .public_site_pricing_table_container {
    padding: 2rem 25rem;
  }
}
