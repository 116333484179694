.public_contact_container_flexbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public_contact_success_message {
  background-color: green;
  width: 100%;
  color: white;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  font-size: 23px;
  padding: 10px;
}

.public_contact_form_flexbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  width: 100%;
}

.public_contact_title {
  padding-top: 20px;
  font-family: var(--fontC);
  font-size: 1.7rem;
  font-weight: 700;
  color: var(--blue);
}

.public_contact_form {
  display: flex;
  flex-direction: column;
  align-items: left;
  min-width: 90%;
  padding: 20px 0;
}

.public_contact_form label {
  text-align: left !important;
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  padding: 5px;
}

.public_contact_form input {
  min-width: 95%;
  padding: 8px;
  border-color: var(--lt_grey);
  border-radius: 10px;
  font-family: var(--fontC);
  font-size: 15px;
}

.public_contact_form textarea {
  padding: 10px;
  min-width: 95%;
  border-color: var(--lt_grey);
  border-radius: 10px;
  font-family: var(--fontC);
  font-size: 15px;
}

.public_contact_button_group button {
  padding: 10px;
  border: none;
  color: white;
  border-radius: 10px;
  font-family: var(--fontA);
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.public_contact_button_reset {
  background-color: red;
  margin-right: 10px;
}

.public_contact_button_submit {
  background-color: var(--blue);
  margin-right: 10px;
}

.public_contact_information {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
}
.public_contact_information_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.public_contact_information_heading {
  color: var(--blue);
  font-family: var(--fontC);
  font-size: 1.5rem;
  padding: 20px 0 5px 0;
}
.public_contact_information_body {
  color: var(--orange);
  font-family: var(--fontC);
  font-size: 1.25rem;
}

@media only screen and (min-width: 576px) {
  .public_contact_title {
    font-size: 2rem;
  }

  .public_contact_form input {
    font-size: 15px;
  }

  .public_contact_form textarea {
    font-size: 15px;
  }

  .public_contact_button_group button {
    padding: 15px;
    font-size: 18px;
  }

  .public_contact_information_heading {
    font-size: 2rem;
  }
  .public_contact_information_body {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 768px) {
  .public_contact_title {
    font-size: 2rem;
  }

  .public_contact_form input {
    font-size: 15px;
  }

  .public_contact_form textarea {
    font-size: 15px;
  }

  .public_contact_button_group button {
    padding: 15px;
    font-size: 18px;
  }

  .public_contact_information_heading {
    font-size: 2rem;
  }
  .public_contact_information_body {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 992px) {
  .public_contact_title {
    font-size: 2rem;
  }

  .public_contact_form input {
    font-size: 15px;
  }
  .public_contact_form label {
    font-size: 20px;
  }

  .public_contact_form textarea {
    font-size: 15px;
  }

  .public_contact_button_group button {
    padding: 15px;
    font-size: 18px;
  }

  .public_contact_information_heading {
    font-size: 2rem;
  }
  .public_contact_information_body {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .public_contact_container_flexbox {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .public_contact_form_flexbox {
    width: 50%;
  }
  .public_contact_title {
    font-size: 2rem;
  }

  .public_contact_form input {
    font-size: 15px;
  }
  .public_contact_form label {
    font-size: 20px;
  }

  .public_contact_form textarea {
    font-size: 15px;
  }

  .public_contact_button_group button {
    padding: 15px;
    font-size: 18px;
  }

  .public_contact_information {
    width: 50%;
  }

  .public_contact_information_heading {
    font-size: 2rem;
  }
  .public_contact_information_body {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 1400px) {
  .public_contact_title {
    font-size: 2rem;
  }

  .public_contact_form input {
    font-size: 15px;
  }
  .public_contact_form label {
    font-size: 22px;
    padding: 5px 0px;
  }

  .public_contact_form textarea {
    font-size: 15px;
  }

  .public_contact_button_group button {
    padding: 15px;
    font-size: 18px;
  }

  .public_contact_information {
    width: 50%;
  }

  .public_contact_information_heading {
    font-size: 2.2rem;
  }
  .public_contact_information_body {
    font-size: 1.75rem;
  }
}
@media only screen and (min-width: 1900px) {
  .public_contact_title {
    font-size: 2.5rem;
  }

  .public_contact_form input {
    font-size: 15px;
  }
  .public_contact_form label {
    font-size: 22px;
    padding: 5px 0px;
  }

  .public_contact_form textarea {
    font-size: 15px;
  }

  .public_contact_button_group button {
    padding: 15px;
    font-size: 18px;
  }

  .public_contact_information {
    width: 50%;
  }

  .public_contact_information_heading {
    font-size: 2.2rem;
  }
  .public_contact_information_body {
    font-size: 1.75rem;
  }
}
