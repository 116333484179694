.public_pricing_container {
  padding-top: 1rem;
}

#bundleSelect {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.public_pricing_bundleSelector_label {
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 1.2rem;
  margin: 0 5px 0 10px;
}

.public_pricing_bundleSelector_select {
  color: var(--orange);
  font-family: var(--fontC);
  /* font-weight: 700; */
  font-size: 0.95rem;
  padding: 2px 5px 2px 5px;
  border-color: var(--lt_grey);
  margin-top: 0.5rem;
  width: 40%;
}

.public_bundle_information_container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-left: 1rem;
}

.public_bundle_information_header {
  font-family: var(--fontC);
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--orange);
}

.public_bundle_information_list ul {
  list-style: circle;
}

.public_bundle_information_list_item {
  font-family: var(--fontC);
  color: var(--dk_grey);
  font-size: 0.8rem;
  font-weight: 700;
}

.public_pricing_table_container {
  padding: 0 10px;
}

.public_pricing_table {
  width: 95%;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
}

.public_pricing_table,
.public_pricing_table th,
.public_pricing_table td {
  border: 1px solid var(--blue);
}

.public_pricing_headline {
  color: var(--orange);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 1.5rem;
  padding-left: 10px;
  padding-bottom: 5px;
}

.public_pricing_table_heading {
  color: var(--lt_grey);
  font-size: 0.65rem;
  border: 1px solid var(--blue);
  text-align: center;
}

.public_pricing_table_heading tr {
  background-color: var(--blue);
}

.public_pricing_table_body tr {
  font-family: var(--fontC);
  font-size: 0.65rem;
  text-align: center;
}

.public_pricing_table_body tr:nth-child(even) {
  background-color: white;
}
.public_pricing_table_body tr:nth-child(odd) {
  background-color: var(--lt_grey);
}

.public_pricing_overage_disclaimer {
  font-size: 10px;
  text-align: right;
  margin-right: 10px;
  margin-top: 5px;
}

.public_orderShippingProcess_container {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding-left: 1rem;
}

.public_orderShippingProcess_flexbox {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.public_orderShippingProcess_headline {
  font-family: var(--fontC);
  color: var(--orange);
  font-weight: 700;
  font-size: 1.5rem;
}

.public_orderShippingProcess_list {
  max-width: 90%;
}

.public_orderShippingProcess_list_items {
  font-family: var(--fontC);
  color: var(--dk_grey);
  font-size: 0.8rem;
  font-weight: 700;
}

.public_pricing_order_button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--orange);
  width: 150px;
  margin: auto;
  padding: auto;
}

.public_pricing_order_button a {
  text-decoration: none;
  font-family: var(--fontC);
  font-weight: 700;
  color: var(--blue);
}
.pricing_gears_1,
.pricing_art_1,
.public_pricing_order_button_top,
.pricing_art_4 {
  display: none;
}

@media only screen and (min-width: 576px) {
  .public_pricing_bundleSelector_label {
    font-size: 1.5rem;
  }

  .public_pricing_bundleSelector_select {
    font-size: 1.2rem;
  }

  .public_bundle_information_header {
    font-size: 1.75rem;
  }
  .public_bundle_information_list_item {
    font-size: 1.2rem;
  }
  .public_pricing_headline {
    font-size: 1.75rem;
  }
  .public_pricing_table_heading {
    font-size: 0.95rem;
  }
  .public_pricing_table_body tr {
    font-size: 0.95rem;
  }
  .public_pricing_overage_disclaimer {
    margin-right: 15px;
  }

  .public_orderShippingProcess_headline {
    font-size: 1.75rem;
  }
  .public_orderShippingProcess_list_items {
    font-size: 1.2rem;
  }

  .public_pricing_order_button {
    padding: 15px 10px;
    width: 170px;
  }
}
@media only screen and (min-width: 768px) {
  .public_pricing_bundleSelector_label {
    font-size: 1.5rem;
  }

  .public_pricing_bundleSelector_select {
    font-size: 1.2rem;
  }

  .public_bundle_information_header {
    font-size: 1.75rem;
  }
  .public_bundle_information_list_item {
    font-size: 1rem;
  }
  .public_pricing_headline {
    font-size: 1.75rem;
  }
  .public_pricing_table_heading {
    font-size: 1.2rem;
  }
  .public_pricing_table_body tr {
    font-size: 1.2rem;
  }
  .public_pricing_overage_disclaimer {
    margin-right: 15px;
  }

  .public_orderShippingProcess_headline {
    font-size: 1.75rem;
  }
  .public_orderShippingProcess_list_items {
    font-size: 1.2rem;
  }

  .public_pricing_order_button {
    padding: 15px 10px;
    width: 170px;
  }
  .pricing_art_1 {
    display: none;
    width: 300px;
    padding-left: 25px;
  }


  .public_pricing_order_button_top {
    display: block;
    padding: 15px 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--orange);
    width: 150px;
  }

  .public_pricing_order_button_top a {
    text-decoration: none;
    font-family: var(--fontC);
    font-size: 16px;
    color: var(--lt_grey);
  }

  .public_bundle_information_container {
    display: flex;
    flex-direction: row;
    align-items: left;
  }
  .public_bundle_flexbox {
    padding-left: 25px;
  }

  .public_pricing_table_container {
    padding: 30px 10px;
  }

  .public_pricing_overage_disclaimer {
    font-size: 13px;
    margin-right: 20px;
  }

  .public_pricing_order_button a {
    font-size: 16px;
  }

  .pricing_art_4 {
    display: block;
    width: 250px;
    padding-right: 15px;
  }
  .public_orderShippingProcess_container {
    flex-direction: row;
    padding-top: 20px;
  }
}
@media only screen and (min-width: 992px) {
  .public_pricing_bundleSelector_label {
    font-size: 1.7rem;
  }

  .public_pricing_bundleSelector_select {
    font-size: 1.3rem;
  }

  .public_bundle_information_header {
    font-size: 2rem;
  }
  .public_bundle_information_list_item {
    font-size: 1.25rem;
  }
  .public_pricing_headline {
    font-size: 2rem;
    padding: 20px;
  }

  .public_pricing_table_heading {
    font-size: 1.35rem;
  }
  .public_pricing_table_body tr {
    font-size: 1.35rem;
  }
  .public_pricing_overage_disclaimer {
    font-size: 16px;
    margin-right: 25px;
  }

  .public_orderShippingProcess_headline {
    font-size: 2rem;
  }
  .public_orderShippingProcess_list_items {
    font-size: 1.25rem;
  }

  .public_pricing_order_button {
    padding: 15px 10px;
    width: 170px;
  }

  .public_pricing_order_button_top {
    display: block;
    padding: 15px 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--orange);
    width: 150px;
  }

  .public_pricing_order_button_top a {
    font-size: 16px;
  }

  .public_pricing_table_container {
    padding: 30px 10px;
  }

  .public_pricing_order_button a {
    font-size: 16px;
  }

  .pricing_art_4 {
    display: block;
    width: 250px;
    padding-right: 15px;
  }

  .pricing_gears_1 {
    display: block;
    width: 500px;
    margin-top: 32px;
    height: 400px;
    padding-left: 12%;
  }
  .public_orderShippingProcess_container {
    flex-direction: row;
    padding-top: 20px;
  }
  @media only screen and (min-width: 1200px) {
    .public_pricing_bundleSelector_label {
      font-size: 2rem;
      padding-left: 30px;
    }

    .public_pricing_bundleSelector_select {
      font-size: 1.5rem;
    }

    .public_bundle_information_header {
      font-size: 2.35rem;
      margin-top: 30px;
    }
    .public_bundle_information_list_item {
      font-size: 1.5rem;
    }
    .pricing_art_1 {
      display: block;
      width: 400px;
      height: 300px;
      margin-top: 32px;

      /* padding-left: 12%; */
    }
    .public_pricing_headline {
      font-size: 2.35rem;
    }

    .public_pricing_table_heading {
      font-size: 1.5rem;
    }
    .public_pricing_table_body tr {
      font-size: 1.5rem;
    }
    .public_pricing_overage_disclaimer {
      font-size: 18px;
      margin-right: 35px;
    }

    .public_orderShippingProcess_headline {
      font-size: 2rem;
    }
    .public_orderShippingProcess_list_items {
      font-size: 1.5rem;
    }

    .public_pricing_order_button {
      padding: 15px 10px;
      width: 170px;
    }

    .public_pricing_order_button_top {
      display: block;
      padding: 15px 10px;
      border: none;
      border-radius: 5px;
      background-color: var(--orange);
      width: 150px;
    }

    .public_pricing_order_button_top a {
      font-size: 16px;
    }

    .public_pricing_order_button a {
      font-size: 18px;
    }

    .pricing_art_4 {
      width: 350px;
      padding-right: 15px;
    }

    .pricing_gears_1 {
      width: 500px;
      margin-top: 32px;
      height: 400px;
      padding-left: 12%;
    }
    .public_orderShippingProcess_container {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 1400px) {
    .public_pricing_bundleSelector_label {
      font-size: 2rem;
      padding-left: 30px;
    }

    .public_pricing_bundleSelector_select {
      font-size: 1.5rem;
    }

    .public_bundle_information_header {
      font-size: 2.5rem;
    }
    .public_bundle_information_list_item {
      font-size: 1.75rem;
      line-height: 35px;
    }
    .pricing_art_1 {
      width: 600px;
      margin-top: 32px;
      height: 300px;
      padding-left: 12%;
    }
    .pricing_gears_1 {
      width: 500px;
      margin-top: 32px;
      height: 400px;
      padding-left: 12%;
    }

    .public_pricing_order_button_top {
      margin-top: 30px;
      margin-left: 20px;
      padding: 15px 15px;
      width: 200px;
    }

    .public_pricing_order_button_top a {
      font-size: 20px;
    }
    .public_pricing_headline {
      font-size: 2.5rem;
    }

    .public_pricing_table_heading {
      font-size: 1.7rem;
    }
    .public_pricing_table_body tr {
      font-size: 1.7rem;
    }
    .public_pricing_overage_disclaimer {
      font-size: 18px;
      margin-right: 35px;
    }

    .public_orderShippingProcess_headline {
      font-size: 2.5rem;
    }
    .public_orderShippingProcess_list_items {
      font-size: 1.75rem;
    }

    .public_pricing_order_button {
      padding: 15px 15px;
      width: 200px;
    }

    .public_pricing_order_button a {
      font-size: 20px;
    }

    .pricing_art_4 {
      width: 350px;
      padding-right: 15px;
    }
    .public_orderShippingProcess_container {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 1500px) {
    .public_pricing_container {
      padding: 1rem 10%;
    }
  }

  @media only screen and (min-width: 1900px) {
    .public_pricing_bundleSelector_label {
      font-size: 2rem;
      padding-left: 30px;
    }

    .public_pricing_bundleSelector_select {
      font-size: 2rem;
      max-width: 20%;
    }

    .public_bundle_information_header {
      font-size: 2.5rem;
      margin-top: 60px;
    }
    .public_bundle_information_list_item {
      font-size: 1.8rem;
      line-height: 35px;
    }
    .pricing_art_1 {
      width: 650px;
      height: 375px;
      padding-left: 5%;
    }

    .pricing_gears_1 {
      width: 500px;
      margin-top: 32px;
      height: 400px;
      padding-left: 12%;
    }

    .public_pricing_table_container {
      padding-top: 60px;
    }

    .public_pricing_order_button_top {
      margin-top: 30px;
      margin-left: 20px;
      padding: 15px 15px;
      width: 200px;
    }

    .public_pricing_order_button_top a {
      font-size: 20px;
    }
    .public_pricing_headline {
      font-size: 3rem;
    }

    .public_pricing_table_heading {
      font-size: 2rem;
    }
    .public_pricing_table_body tr {
      font-size: 2rem;
    }
    .public_pricing_overage_disclaimer {
      font-size: 18px;
      margin-right: 35px;
    }

    .public_orderShippingProcess_headline {
      font-size: 3rem;
    }
    .public_orderShippingProcess_list_items {
      font-size: 1.5rem;
      line-height: 30px;
    }

    .public_pricing_order_button {
      padding: 20px 20px;
      width: 300px;
    }

    .public_pricing_order_button a {
      font-size: 25px;
    }

    .pricing_art_4 {
      width: 550px;
      padding-right: 15px;
    }
    .public_orderShippingProcess_container {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 2200px) {
    .public_pricing_bundleSelector_label {
      font-size: 2.5rem;
      padding-left: 30px;
    }

    .public_pricing_bundleSelector_select {
      font-size: 2rem;
    }

    .public_bundle_information_header {
      font-size: 3.25rem;
      margin-top: 60px;
    }
    .public_bundle_information_list_item {
      font-size: 2.25rem;
      line-height: 50px;
    }
    .pricing_art_1 {
      width: 700px;
      height: 400px;
      padding-left: 18%;
    }

    .pricing_gears_1 {
      width: 600px;
      margin-top: 22px;
      height: 480px;
      padding-left: 12%;
    }

    .public_pricing_table_container {
      padding-top: 60px;
    }

    .public_pricing_order_button_top {
      margin-top: 30px;
      margin-left: 20px;
      padding: 15px 15px;
      width: 200px;
    }

    .public_pricing_order_button_top a {
      font-size: 20px;
    }
    .public_pricing_headline {
      font-size: 3.25rem;
    }

    .public_pricing_table_heading {
      font-size: 2.25rem;
    }
    .public_pricing_table_body tr {
      font-size: 2.25rem;
    }
    .public_pricing_overage_disclaimer {
      font-size: 22px;
      margin-right: 55px;
    }

    

    .public_orderShippingProcess_headline {
      font-size: 3.25rem;
    }
    .public_orderShippingProcess_list_items {
      font-size: 2.25rem;
      line-height: 60px;
    }

    .public_pricing_order_button {
      padding: 20px 20px;
      width: 300px;
    }

    .public_pricing_order_button a {
      font-size: 25px;
    }

    .pricing_art_4 {
      width: 550px;
      padding-right: 15px;
    }
    .public_orderShippingProcess_container {
      padding-top: 20px;
    }
  }
  @media only screen and (min-width: 2500px) {
    .public_pricing_bundleSelector_label {
      font-size: 2.5rem;
      padding-left: 30px;
    }

    .public_pricing_bundleSelector_select {
      font-size: 2rem;
    }

    .public_bundle_information_header {
      font-size: 4rem;
      margin-top: 60px;
      margin-left: 5%;
    }
    .public_bundle_information_list_item {
      font-size: 3rem;
      line-height: 70px;
      margin-left: 5%;
    }
    .pricing_art_1 {
      width: 1020px;
      padding-left: 20%;
    }

    .pricing_gears_1 {
      width: 700px;
      margin-top: 32px;
      height: 550px;
      padding-left: 12%;
    }

    .public_pricing_table_container {
      padding-top: 60px;
    }

    .public_pricing_order_button_top {
      margin-top: 30px;
      margin-left: 20px;
      padding: 15px 15px;
      width: 200px;
      margin-left: 5%;
    }

    .public_pricing_order_button_top a {
      font-size: 20px;
    }
    .public_pricing_headline {
      font-size: 4rem;
      margin-left: 2%;
    }

    .public_pricing_table_heading {
      font-size: 3rem;
    }
    .public_pricing_table_body tr {
      font-size: 3rem;
    }
    .public_pricing_overage_disclaimer {
      font-size: 22px;
      margin-right: 55px;
    }

    .public_orderShippingProcess_headline {
      font-size: 4rem;
      margin-left: 2%;
    }
    .public_orderShippingProcess_list_items {
      font-size: 3rem;
      line-height: 80px;
      margin-left: 2%;
    }

    .public_pricing_order_button {
      padding: 20px 20px;
      width: 300px;
    }

    .public_pricing_order_button a {
      font-size: 25px;
    }

    .pricing_art_4 {
      width: 650px;
      padding-right: 15px;
    }
    .public_orderShippingProcess_container {
      padding-top: 20px;
    }
  }
}
