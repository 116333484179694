.customerPortal {
  color: var(--orange);
  font-size: 1rem;
}

.portalFeaturesList {
  /* margin-left: 1rem; */
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media only screen and (min-width: 576px) {
  .customerPortal {
    font-size: 1.5rem;
  }
  .portalFeaturesList {
    margin-left: 6rem;
    margin-top: 2rem;
  }
}

@media only screen and (min-width: 2400px) {
  .customerPortal {
    font-size: 2.5rem;
  }
}
