.public_order_site_summary_container {
  padding: 1rem;
}

.public_order_headline_summary {
  font-family: var(--fontC);
  color: var(--blue);
  font-weight: bold;
  font-size: 1.3rem;
  text-align: center;
}

.public_order_summary_title {
  color: var(--orange);
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  padding: 1rem 0;
}

.public_orderSummary_customer_info_table {
  margin-top: 1rem;
  padding: 0.2rem;
  width: 100%;
}

.public_orderSummary_customer_info_table th {
  text-align: left;
  width: 20%;
}

.public_order_site_table_heading th {
  color: white;
}

.public_orderSummary_customer_info_table td {
  text-align: left;
}

.public_orderSummary_billing_information_section {
  margin-top: 1rem;
  padding: 0.2rem;
  width: 100%;
}

.public_order_summary_totals th {
  color: var(--blue);
  font-weight: bold;
}
.public_order_summary_totals td {
  font-weight: bold;
}

.public_orderSummary_billing_information_section th {
  text-align: left;
  width: 40%;
}

.public_orderSummary_billing_information_section td {
  text-align: left;
}

.public_orderSummary_agreement_terms {
  font-family: var(--fontC);
  font-size: 0.8rem;
  padding: 0.3rem;
}

/* *****************************  576  ********************************************************* */
@media only screen and (min-width: 576px) {
  .public_orderSummary_agreement_terms {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}
/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  .public_orderSummary_agreement_terms {
    font-size: 1rem;
    padding: 0.8rem;
  }
}
/* *****************************  1200  ********************************************************* */
@media only screen and (min-width: 1200px) {
  .public_orderSummary_agreement_terms {
    font-size: 1.2rem;
  }
}
/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  .public_orderSummary_agreement_terms {
    font-size: 1.5rem;
  }
}
