@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;900&family=Source+Sans+Pro:ital,wght@0,400;0,700;0,900;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

.App {
  /* --fontA: "Montserrat", sans-serif; */
  --fontA: "Montserrat", sans-serif;
  --fontB: "Source Sans Pro", sans-serif;
  /* --fontC: "'Noto Sans JP', sans-serif"; */
  --fontC: "Roboto", sans-serif;
  --blue: #2e66b0;
  --lt_grey: #e2e2e2;
  --dk_grey: #acacac;
  --orange: #f5780a;
  --orangeHover: #f3a159;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.title {
  font-family: var(--fontA);
  font-size: 25px;
  color: var(--orange);
}

#backDrop {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

h1 {
  color: var(--blue);
  font-weight: bold;
  font-size: 1.2rem;
  font-family: var(--fontC);
}

h2 {
  color: var(--orange);
  font-weight: bold;
  font-size: 1rem;
  font-family: var(--fontC);
}

button {
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-family: var(--fontC);
  cursor: pointer;
}

label {
  color: var(--blue);
  font-size: 0.8rem;
  font-weight: bold;
}

input {
  font-size: 0.8rem;
  padding: 0.5rem;
  color: var(--blue);
  border: 1px solid var(--blue);
}

select {
  font-size: 0.8rem;
  padding: 0.5rem;
  color: var(--blue);
  border: 1px solid var(--blue);
  font-family: var(--fontC);
  border-radius: 5px;
}

th {
  color: var(--blue);
  font-size: 0.8rem;
  font-weight: bold;
  font-family: var(--fontC);
}

td {
  font-size: 0.8rem;
  color: var(--blue);
  font-family: var(--fontC);
}

textarea {
  font-size: 1rem;
  color: var(--blue);
  border: 1px solid var(--blue);
}

/* *****************************  576  ********************************************************* */
@media only screen and (min-width: 576px) {
  h1 {
    font-size: 1.3rem;
  }

  h2 {
    font-size: 1.1rem;
  }

  button {
    padding: 0.8rem;
  }
}
/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  label {
    font-size: 1rem;
  }
  input {
    font-size: 1rem;
  }
  select {
    font-size: 1rem;
  }
}
/* *****************************  992  ********************************************************* */
@media only screen and (min-width: 992px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.3rem;
  }
}
/* *****************************  1200  ********************************************************* */
@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 1.7rem;
  }

  h2 {
    font-size: 1.4rem;
  }
}
/* *****************************  1400  ********************************************************* */
@media only screen and (min-width: 1400px) {
  h1 {
    font-size: 1.9rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  textarea {
    font-size: 1.2rem;
  }
}
/* *****************************  1900  ********************************************************* */
@media only screen and (min-width: 1900px) {
  h1 {
    font-size: 2.1rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  button {
    padding: 1rem;
  }

  th {
    font-size: 1.2rem;
  }

  td {
    font-size: 1.2rem;
  }
}
/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  label {
    font-size: 1.5rem;
  }
  input {
    font-size: 1.5rem;
  }
  select {
    font-size: 1.5rem;
  }

  th {
    font-size: 1.5rem;
  }

  td {
    font-size: 1.5rem;
  }
}
/* *****************************  2900  ********************************************************* */
@media only screen and (min-width: 2900px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.1rem;
  }
}
