*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.public_orderProcessing_container {
  background-color: var(--blue);
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 72vh;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.public_orderProcessing_status {
  color: var(--orange);
}

.public_orderProcessing_spinner {
  width: 90px;
  height: 90px;
  position: relative;
  background: rgba(255, 255, 255, 0.13);
  animation-duration: 2.5s;
  animation-name: animSpin;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes animSpin {
  50% {
    transform: rotateZ(180deg) scale(0.94);
  }
  100% {
    transform: rotateZ(360deg) scale(1);
  }
}

.public_orderProcessing_spinner,
.public_orderProcessing_spinner:before,
.public_orderProcessing_spinner:after {
  border-radius: 50%;
}

.public_orderProcessing_spinner:before,
.public_orderProcessing_spinner:after {
  content: "";
  position: absolute;
  border: 8px solid transparent;
}

.public_orderProcessing_spinner:before {
  width: 75%;
  height: 75%;
  background: rgba(49, 48, 48, 0.13);
  left: 10%;
  top: 10%;
  border-left: 8px solid rgba(255, 255, 255, 0.34);
  border-bottom: 8px solid rgba(255, 255, 255, 0.34);
}

.public_orderProcessing_spinner:after {
  width: 40%;
  height: 40%;
  left: 30%;
  top: 30%;
  border-right: 8px solid rgba(255, 255, 255, 1);
  border-left: 8px solid rgba(255, 255, 255, 1);
  border-bottom: 8px solid rgba(255, 255, 255, 1);
}
