.public_footer_container {
  background-color: var(--blue);
  min-height: 100px;
  padding: 0;
  margin: 0;
}

.public_header_mobile_flexbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.public_header_mobile_flexbox img {
  width: 150px;
}

.public_footer_contact_info ul {
  list-style: none;
  margin-right: 10px;
}

.public_footer_contact_info_items {
  font-family: var(--fontB);
  font-weight: 700;
  color: var(--orange);
  font-size: 10px;
  text-align: right;
}
.public_footer_contact_info_items span {
  color: var(--lt_grey);
  font-weight: 400;
}
@media only screen and (min-width: 576px) {
  .public_header_mobile_flexbox img {
    width: 200px;
  }
  .public_footer_contact_info_items {
    font-size: 16px;
  }
}
@media only screen and (min-width: 768px) {
  .public_header_mobile_flexbox img {
    width: 220px;
  }
  .public_footer_contact_info_items {
    font-size: 18px;
  }
}
@media only screen and (min-width: 992px) {
  .public_header_mobile_flexbox img {
    width: 245px;
    margin-left: 5%;
  }
  .public_footer_contact_info_items {
    font-size: 18px;
    margin-right: 25px;
  }
}
@media only screen and (min-width: 1200px) {
  .public_header_mobile_flexbox img {
    width: 275px;
  }
  .public_footer_contact_info_items {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1400px) {
  .public_header_mobile_flexbox img {
    width: 325px;
  }
  .public_footer_contact_info_items {
    font-size: 25px;
  }
}
@media only screen and (min-width: 1900px) {
  .public_header_mobile_flexbox img {
    width: 350px;
  }
  .public_footer_contact_info_items {
    font-size: 28px;
  }
}
@media only screen and (min-width: 2500px) {
  .public_header_mobile_flexbox img {
    width: 375px;
  }
  .public_footer_contact_info_items {
    font-size: 32px;
    margin-right: 35px;
  }
}
