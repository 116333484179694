.public_order_billing_container {
  padding: 1rem;
}

.public_order_headline_billing {
  font-family: var(--fontC);
  color: var(--blue);
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
}

.public_error_message_billing {
  color: red;
  background-color: yellow;
  font-weight: bold;
  text-align: center;
}

.public_order_billing_title {
  color: var(--orange);
  font: var (--fontC);
  font-weight: bold;
  padding: 1.2rem 0 0.8rem 0;
}

.public_order_billing_card_type_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1rem 0;
  color: var(--blue);
  font-size: 0.8rem;
}

#card_type {
  width: 0.8rem;
  height: 0.8rem;
}

.public_billing_info_card {
  display: flex;
  flex-direction: column;
}

.public_billing_info_card label {
  padding: 0.3rem 0;
}
.public_billing_info_card input {
  font-weight: bold;
  border-radius: 5px;
}

.public_billing_date_ccv_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  min-width: 100%;
}

.public_billing_info_expiration {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-evenly;
  padding-left: 10px;
}

.public_billing_info_expiration input {
  width: 40%;
  text-align: right;
}
.public_billing_info_expiration input::placeholder {
  color: var(--lt_grey);
}
.public_billing_info_expiration span {
  font-weight: bold;
  padding: 0 0.5rem;
  font-size: 1.2rem;
}

#card_dateB {
  width: 50%;
}
.public_billing_info_ccv_container {
  padding-left: 10px;
}

#card_code {
  margin-left: 0.5rem;
  width: 50%;
}

.public_billing_card_contact {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.public_billing_card_contact label {
  padding: 0.3rem 0;
}
.public_billing_card_contact input {
  border-radius: 5px;
}

.public_order_billing_state_select {
  width: 100%;
}

/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  #card_type {
    width: 1.2rem;
    height: 1.2rem;
  }
}

/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  #card_type {
    width: 1.5rem;
    height: 1.5rem;
  }
}
