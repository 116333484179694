.public_hero_container::before {
  content: "";
  background-image: url(../media/ConnectedDots.png);
  background-repeat: repeat;
  position: absolute;
  top: 200px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  opacity: 0.25;
  z-index: -3;
}

.public_hero_mobile_flexbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stayConnected_button_desktop {
  display: none;
}

.public_hero_text_headline {
  margin-top: 20px;
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 25px;
  text-align: center;
}

.public_hero_text_description {
  padding: 5px 25px;
  text-align: center;
  font-family: var(--fontB);
  font-size: 15px;
  color: var(--dk_grey);
}

.public_hero_text_description span {
  color: var(--orange);
  font-weight: 700;
}

.public_hero_image img {
  margin-top: 15px;
  width: 100%;
}

.stayConnected_button {
  background-color: var(--orange);
  border: none;
  padding: 15px 10px;
  border-radius: 10px;
  font-family: var(--fontA);
  font-weight: 700;
  font-size: 15px;
  color: var(--lt_grey);
  margin-top: 30px;
  margin-bottom: 20px;
  cursor: pointer;
}
.stayConnected_button a {
  text-decoration: none;
  font-family: var(--fontA);
  font-weight: 700;
  font-size: 15px;
  color: var(--lt_grey);
  cursor: pointer;
}
.stayConnected_button:hover {
  background-color: var(--orangeHover);
  color: var(--blue);
}

@media only screen and (min-width: 576px) {
  .public_hero_text_description {
    padding: 5px 45px;
    font-size: 18px;
  }
  .public_hero_image img {
    width: 500px;
  }

  .public_hero_text_headline {
    font-size: 35px;
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .public_hero_text {
    width: 100%;
    padding: 0 0 0 5%;
  }

  .public_hero_text_headline {
    font-weight: 700;
    font-size: 35px;
  }

  .public_hero_text_description {
    font-size: 20px;
  }

  .public_hero_image img {
    width: 500px;
  }
}

@media only screen and (min-width: 992px) {
  .public_hero_mobile_flexbox {
    flex-direction: row;
    justify-content: space-between;
  }

  .public_hero_button_mobile {
    display: none;
  }
  .stayConnected_button_desktop {
    display: block;
    background-color: var(--orange);
    border: none;
    padding: 15px 10px;
    border-radius: 5px;
    font-family: var(--fontC);
    font-size: 15px;
    margin-top: 30px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .stayConnected_button_desktop a {
    text-decoration: none;
    color: var(--lt_grey);
    cursor: pointer;
  }
  .stayConnected_button_desktop:hover {
    background-color: var(--orangeHover);
    color: var(--blue);
  }
  .public_hero_image img {
    margin-top: 0;
    width: 500px;
    margin-right: 40px;
  }
  .public_hero_text {
    width: 100%;
    padding: 0 0 0 5%;
  }

  .public_hero_text_headline {
    padding: 0;
    margin-top: 20px;
    font-weight: 700;
    font-size: 35px;
    text-align: left;
    max-width: 80%;
  }

  .public_hero_text_description {
    padding: 0 25px 0 0;
    text-align: left;
    font-size: 20px;
    max-width: 80%;
  }
}

@media only screen and (min-width: 1500px) {
  .public_hero_container {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 1900px) {
  .public_hero_image img {
    width: 700px;
    margin-right: 80px;
  }
  .public_hero_text_headline {
    margin-top: 20px;
    font-weight: 700;
    font-size: 40px;
    text-align: left;
    max-width: 80%;
  }

  .public_hero_text_description {
    padding: 0 25px 0 0;
    text-align: left;
    font-size: 23px;
    max-width: 80%;
  }
}
@media only screen and (min-width: 2500px) {
  .public_hero_mobile_flexbox {
    flex-direction: row;
    justify-content: space-between;
  }

  .public_hero_button_mobile {
    display: none;
  }
  .stayConnected_button_desktop {
    padding: 25px 18px;
    border-radius: 15px;
    font-size: 29px;
    color: var(--blue);
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .public_hero_image img {
    width: 1024px;
    margin-right: 100px;
  }
  .public_hero_text {
    width: 100%;
    padding: 0 0 0 5%;
  }

  .public_hero_text_headline {
    padding: 0;
    margin-top: 20px;
    font-weight: 700;
    font-size: 65px;
    text-align: left;
    max-width: 80%;
  }

  .public_hero_text_description {
    padding: 0 25px 0 0;
    text-align: left;
    font-size: 50px;
    max-width: 80%;
  }
}
