.public_orderComplete_container {
  background-color: var(--lt_grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 72vh;
  margin: 0;
  text-align: center;
}

.public_orderComplete_status {
  color: var(--blue);
  font-size: 1.2rem;
}

/* *****************************  992  ********************************************************* */
@media only screen and (min-width: 992px) {
  .public_orderComplete_status {
    font-size: 2rem;
  }
}
