.public_order_container {
  padding: 1rem 1rem 5rem 1rem;
}
.public_order_contacts_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem;
}

.public_order_customer_information_desktop {
  display: none;
}

.public_order_headline {
  color: var(--blue);
  font-family: var(--fontA);
  font-weight: 700;
  font-size: 0.8rem;
  padding-top: 3%;
  text-align: center;
}

.public_order_section_card {
  background-color: var(--lt_grey);
  margin: 1rem 0;
  padding: 30px 20px;
  border: none;
  border-radius: 5px;
  width: 100%;
}
.public_order_referral {
  font-family: var(--fontC);
  font-size: 0.85rem;
}

.public_order_form {
  color: var(--blue);
  font-family: var(--fontC);
}

.public_order_section_card_title {
  color: var(--orange);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 0.8rem;
  padding: 5% 0 2% 0;
  margin-bottom: 1rem;
}

.public_order_form_text_input {
  color: var(--blue);
  min-width: 90%;
  border-color: var(--blue);
  border-radius: 5px;
  margin: 0.5rem 0;
  width: 100%;
}

.public_order_section_state_select {
  width: 100%;
  margin: 1rem 0;
  padding: 0.5rem;
  font-family: var(--fontC);
  color: var(--blue);
}

.public_order_form_label {
  padding-left: 0.1rem;
  padding-top: 2rem;
}

.public_order_navigation_buttons {
  width: 100%;
}

.public_order_navigation_buttons button {
  margin-top: 1em;
  padding: 1.2rem;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  font-family: var(--fontC);
}

.order_cancel {
  background-color: red;
  border: none;
  border-radius: 5px;
  width: 100%;
}
.order_next {
  background-color: var(--blue);
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 1rem;
}

.public_error_message {
  color: red;
  background: yellow;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 5px;
  margin-left: 2px;
}

/* *****************************  576  ********************************************************* */
@media only screen and (min-width: 576px) {
  .public_order_headline {
    font-size: 1.2rem;
    padding-top: 5%;
  }

  .public_order_section_card {
    padding: 2rem 1.5rem;
  }

  .public_order_referral {
    font-size: 1rem;
  }

  .public_order_section_card_title {
    font-size: 1rem;
  }

  .public_order_section_state_select {
    font-size: 1rem;
  }

  .public_order_navigation_buttons {
    width: 100%;
  }

  .public_order_navigation_buttons button {
    margin-top: 1em;
    padding: 1.2rem;
    font-size: 1rem;
  }

  .order_cancel {
    width: 100%;
  }
  .order_next {
    width: 100%;
  }

  .public_error_message {
    font-weight: bold;
  }

  .public_order_add_site_button {
    margin-top: 1rem;
    border-radius: 5px;
    background: green;
    color: var(--lt_grey);
  }

  .public_order_site_table {
    margin: 2rem 0;
    width: 100%;
    border: 1px black solid;
    font-size: 0.9rem;
  }

  .public_order_site_table thead tr {
    border: 1px black solid;
  }

  .public_order_site_table_heading {
    text-align: center;
    padding: 0;
    margin: 0;
  }
}

/* *****************************  768  ********************************************************* */
@media only screen and (min-width: 768px) {
  .public_order_container {
    padding: 2rem 4rem 8rem 4rem !important;
  }

  .public_order_headline {
    font-size: 2rem;
  }

  .public_order_section_card_title {
    font-size: 1.5rem;
  }

  .public_order_navigation_buttons {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_order_navigation_buttons button {
    padding: 1rem 0.5rem;
    font-size: 1.2rem;
  }

  .order_cancel {
    width: 40%;
  }
  .order_next {
    width: 40%;
    margin-bottom: 0;
  }

  .public_error_message {
    font-weight: bold;
    font-size: 1.2rem;
  }
}
/* *****************************  992  ********************************************************* */
@media only screen and (min-width: 992px) {
  .public_order_container {
    padding: 2rem 6rem 8rem 6rem !important;
  }
  .public_order_customer_information_mobile {
    display: none;
  }
  .public_order_customer_information_desktop {
    display: block;
  }

  .public_order_headline {
    padding-top: 5%;
  }

  .public_order_section_card {
    padding: 2rem 1.5rem;
  }

  .public_order_referral {
    font-size: 1rem;
  }

  .public_order_section_card_title {
    font-size: 1.2rem;
  }

  .public_order_section_state_select {
    font-size: 1rem;
  }

  .public_order_form_label_group {
    width: 100%;
  }

  .public_order_customer_information_desktop_labels {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_labels :first-child {
    min-width: 55%;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :nth-child(2) {
    min-width: 21%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :last-child {
    min-width: 20%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_inputs {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_inputs :first-child {
    min-width: 55%;
  }
  .public_order_customer_information_desktop_inputs :nth-child(2) {
    min-width: 5%;
    margin: 0.5rem 0 0.5rem 1rem;
    border-radius: 5px;
  }
  .public_order_customer_information_desktop_inputs :nth-child(3) {
    min-width: 20%;
    margin-left: 1rem;
  }
  .public_order_contact_flex {
    display: flex;
    flex-direction: row;
  }
  .contact_card {
    width: 60%;
    margin-left: 1rem;
    padding: 1.5rem 1rem;
  }

  .public_order_navigation_buttons {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_order_navigation_buttons button {
    margin-top: 1em;
    padding: 1rem 0.5rem;
    font-size: 1.2rem;
  }

  .order_cancel {
    width: 30%;
  }
  .order_next {
    width: 30%;
    margin-bottom: 0;
  }

  .public_error_message {
    font-weight: bold;
    font-size: 1.2rem;
  }
}
/* *****************************  1200  ********************************************************* */
@media only screen and (min-width: 1200px) {
  .public_order_container {
    padding: 2rem 8rem 10rem 8rem !important;
  }

  .public_order_headline {
    font-size: 2rem;
  }

  .public_order_referral {
    font-size: 1.2rem;
  }

  .public_order_section_card_title {
    font-size: 1.5rem;
  }

  .public_order_section_state_select {
    font-size: 1rem;
  }

  .public_order_form_label_group {
    width: 100%;
  }

  .public_order_navigation_buttons button {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    font-size: 1.2rem;
  }

  .order_cancel {
    width: 25%;
  }
  .order_next {
    width: 25%;
  }
}
/* *****************************  1400  ********************************************************* */
@media only screen and (min-width: 1400px) {
  .public_order_container {
    padding: 2rem 20rem 10rem 20rem !important;
  }

  .public_order_headline {
    padding-top: 5%;
  }

  .public_order_section_card {
    padding: 2rem 1.5rem;
  }

  .public_order_section_card_title {
    font-size: 1.5rem;
  }

  .public_order_navigation_buttons button {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  .order_cancel {
    width: 25%;
  }
  .order_next {
    width: 25%;
  }
}
/* *****************************  1900  ********************************************************* */
@media only screen and (min-width: 1900px) {
  .public_order_container {
    padding: 2rem 30rem 10rem 30rem !important;
  }

  .public_order_headline {
    font-size: 2rem;
    padding-top: 5%;
  }

  .public_order_section_card {
    padding: 2rem 1.5rem;
  }

  .public_order_referral {
    font-size: 1.2rem;
  }

  .public_order_section_card_title {
    font-size: 1.5rem;
  }

  .public_order_section_state_select {
    font-size: 1rem;
  }

  .public_order_form_label_group {
    width: 100%;
  }

  .public_order_customer_information_desktop_labels {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_labels :first-child {
    min-width: 55%;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :nth-child(2) {
    min-width: 21%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :last-child {
    min-width: 20%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_inputs {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_inputs :first-child {
    min-width: 55%;
  }
  .public_order_customer_information_desktop_inputs :nth-child(2) {
    min-width: 5%;
    margin: 0.5rem 0 0.5rem 1rem;
    border-radius: 5px;
  }
  .public_order_customer_information_desktop_inputs :nth-child(3) {
    min-width: 20%;
    margin-left: 1rem;
  }
  .public_order_contact_flex {
    display: flex;
    flex-direction: row;
  }
  .contact_card {
    width: 60%;
    margin-left: 1rem;
    padding: 1.5rem 1rem;
  }

  .public_order_navigation_buttons {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_order_navigation_buttons button {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    padding: 1rem 0.5rem;
    font-size: 1.2rem;
  }

  .order_cancel {
    width: 25%;
  }
  .order_next {
    width: 25%;
  }

  .public_error_message {
    font-weight: bold;
    font-size: 1.2rem;
  }
}
/* *****************************  2400  ********************************************************* */
@media only screen and (min-width: 2400px) {
  .public_order_container {
    padding: 2rem 40rem 10rem 40rem !important;
  }

  .public_order_headline {
    font-size: 3rem;
    padding-top: 5%;
  }

  .public_order_section_card {
    padding: 2rem 1.5rem;
  }

  .public_order_referral {
    font-size: 1.5rem;
  }

  .public_order_section_card_title {
    font-size: 2rem;
  }

  .public_order_form_text_input {
    font-size: 1.2rem;
  }

  .public_order_section_state_select {
    font-size: 1.2rem;
  }

  .public_order_form_label_group {
    width: 100%;
  }

  .public_order_customer_information_desktop_labels {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_labels :first-child {
    min-width: 55%;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :nth-child(2) {
    min-width: 21%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :last-child {
    min-width: 20%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_inputs {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_inputs :first-child {
    min-width: 55%;
  }
  .public_order_customer_information_desktop_inputs :nth-child(2) {
    min-width: 5%;
    margin: 0.5rem 0 0.5rem 1rem;
    border-radius: 5px;
  }
  .public_order_customer_information_desktop_inputs :nth-child(3) {
    min-width: 20%;
    margin-left: 1rem;
  }
  .public_order_contact_flex {
    display: flex;
    flex-direction: row;
  }
  .contact_card {
    width: 60%;
    margin-left: 1rem;
    padding: 1.5rem 1rem;
  }

  .public_order_navigation_buttons {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_order_navigation_buttons button {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    padding: 1rem 0.5rem;
    font-size: 1.2rem;
  }

  .order_cancel {
    width: 25%;
  }
  .order_next {
    width: 25%;
  }

  .public_error_message {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .public_order_headline {
    font-size: 3rem;
    padding-top: 5%;
  }

  .public_order_section_card {
    padding: 3rem 3rem;
  }

  .public_order_referral {
    font-size: 1.5rem;
  }

  .public_order_section_card_title {
    font-size: 2rem;
  }

  .public_order_section_state_select {
    font-size: 1.2rem;
  }

  .public_order_form_label_group {
    width: 100%;
  }

  .public_order_customer_information_desktop_labels {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_labels :first-child {
    min-width: 55%;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :nth-child(2) {
    min-width: 21%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_labels :last-child {
    min-width: 20%;
    margin-left: 1rem;
    padding-top: 0;
  }
  .public_order_customer_information_desktop_inputs {
    display: flex;
    flex-direction: row;
  }

  .public_order_customer_information_desktop_inputs :first-child {
    min-width: 55%;
  }
  .public_order_customer_information_desktop_inputs :nth-child(2) {
    min-width: 5%;
    margin: 0.5rem 0 0.5rem 1rem;
    border-radius: 5px;
  }
  .public_order_customer_information_desktop_inputs :nth-child(3) {
    min-width: 20%;
    margin-left: 1rem;
  }
  .public_order_contact_flex {
    display: flex;
    flex-direction: row;
  }
  .contact_card {
    width: 60%;
    margin-left: 1rem;
    padding: 2rem 3rem 4rem 3rem;
  }

  .public_order_navigation_buttons {
    width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .public_order_navigation_buttons button {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    padding: 2rem 1rem;
    font-size: 2rem;
  }

  .order_cancel {
    width: 20%;
  }
  .order_next {
    width: 20%;
  }

  .public_error_message {
    font-weight: bold;
    font-size: 1.2rem;
  }
}

/* *****************************  2900  ********************************************************* */
@media only screen and (min-width: 2900px) {
  .public_order_container {
    padding: 2rem 50rem 10rem 50rem !important;
  }
}
