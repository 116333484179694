.public_site_edit_site_container {
  padding: 1rem;
}

.public_edit_order_bundle_button {
  margin-top: 1rem;
}

.public_edit_order_bundle_button button {
  background-color: var(--orange);
  color: white;
  margin-left: 0.3rem;
}

.public_edit_site_form {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.public_edit_site_form label {
  text-align: left !important;
  color: var(--blue);
  font-family: var(--fontC);
  font-weight: 700;
  padding: 0.3rem 0;
}

.public_order_edit_state_select {
  width: 100%;
  padding: 0.5rem;
  font-family: var(--fontC);
  color: var(--blue);
  border-radius: 5px;
}

.public_edit_site_form input {
  min-width: 95%;
  border-color: var(--lt_grey);
  border-radius: 10px;
  font-family: var(--fontC);
}

.public_edit_site_card_title {
  color: var(--orange);
  font-family: var(--fontC);
  font-weight: 700;
  font-size: 0.8rem;
  padding: 5% 0 2% 0;
  margin-bottom: 1rem;
}

.public_edit_section_card {
  background-color: var(--lt_grey);
  margin: 1rem 0;
  padding: 1.3rem 1.3rem 3rem 1.3rem;
  border: none;
  border-radius: 5px;
  width: 100%;
}

.public_edit_section_card textarea {
  padding: 0.8rem;
  width: 100%;
}

.site_bundle_reset_edit {
  color: red;
  text-decoration: underline;
  font-weight: bold;
  margin: 0.2rem 0 0 0.8rem;
  font-size: 0.8rem;
}

.public_edit_site_copy_billing_button {
  background-color: var(--orange);
  color: white;
  font-size: 0.9rem;
}
